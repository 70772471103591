import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import {createProxyMiddleware} from "http-proxy-middleware";

const root = ReactDOM.createRoot(
	document.getElementById('root')!,
);
root.render(
	<React.StrictMode>
		<meta name='viewport' content='width=device-width, initial-scale=1.0' />
		{/* <AuthWall childrenGetter={() => (<App />)}></AuthWall> */}
		<App />
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Const app = express()
// const proxyPort = 3000
// app.listen(proxyPort)

// app.use(
//     '/v1/state',
//     createProxyMiddleware({
//         target: 'http://sandbox.eba-utz3n499.us-west-2.elasticbeanstalk.com',
//         changeOrigin: true,
//     })
// );
//
// app.use(
//     '/v1/simulate',
//     createProxyMiddleware({
//         target: 'http://sandbox.eba-utz3n499.us-west-2.elasticbeanstalk.com',
//         changeOrigin: true,
//     })
// );

