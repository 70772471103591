import {Player} from '../../../poker/player';
import {GameState, type TGameState} from '../../../poker/game-state';
import {XButton} from '../../components/x-button';
import React, {type CSSProperties} from 'react';
import {Overlay} from '../../components/overlay';
import {Card, Cards} from '../../../poker/card';
import {ContainerNoBorderView} from '../containers/container-no-border-view';
import {Container} from 'react-bootstrap';
import {playerToDisplayName} from '../player/player-view';
import {CardView} from '../card/card-view';
import {
	type TProbabilityResponseSample,
} from '../../../backend/current-state/simulation-request-manager';
import {
	type TCurrentStateMappedResponse,
	fetchCurrentGameState,
} from '../../../backend/current-state/fetch-current-game-state';

export type TSampleOverlay = {
	gameStateManager: GameState;
	style?: CSSProperties;
	onInfoToggle: () => Promise<void>;
	sample: TProbabilityResponseSample;
};

// Export type TSimulationViewPlayerWithUICards = {
//     name: string
//     cards: Card[]
// }

let fetched = false;
let sampleCommunityCards: Card[] = [];
let samplePlayers: Player[] = [];

let latestGameStateResult: TCurrentStateMappedResponse | undefined;

export function setNeedToFetch() {
	fetched = false;
}

async function updateSampleGameStage(sampleStateManager: GameState) {
	await fetchCurrentGameState(sampleStateManager)
		.then(async result => {
			latestGameStateResult = result;
			await sampleStateManager.updateStateFields('sample game state result received', {
				gameStateResult: result,
				communityCards: sampleCommunityCards,
				players: samplePlayers,
			})
				.catch(e => {
					console.error('Error during sendSimulation', e);
				});
		})
		.catch(e => {
			console.error('Error during sendSimulation', e);
		});
}

function SampleView({sampleStateManager}: {sampleStateManager: GameState}) {
	if (!fetched) {
		fetched = true;
		updateSampleGameStage(sampleStateManager)
			.catch(console.error);
	}

	const cardHeight = '6vw';

	return (
		<div style={{padding: 0}}>
			<div style={{textAlign: 'center', padding: `calc(${cardHeight}/4)`}}>
				<h5>Table Cards</h5>
				<Container style={{padding: 0}}>
					{sampleStateManager.state.communityCards.map(card => <CardView
						card={card}
						key={'sample_community_card' + card.id}
						style={{
							marginTop: `calc(${cardHeight} / 4)`,
							marginBottom: `calc(${cardHeight} / 4)`,
							marginRight: 0,
							marginLeft: 0,
							// Border: '0px solid white',
							cursor: 'auto',
						}}
						className={'sample'}
					></CardView>)}
				</Container>
			</div>
			<ContainerNoBorderView style={{textAlign: 'center'}}>
				{sampleStateManager.state.players.map(player => (
					<div key={'player_' + player.id} style={{padding: `calc(${cardHeight}/4)`}}>
						<h5>{playerToDisplayName(player)}</h5>
						<h5 style={{whiteSpace: 'pre-line'}}>{player.handName ?? '‎'}</h5>

						<Container style={{padding: 0}}>
							{player.cards.map(card => <CardView
								card={card}
								key={'simulation_' + card.id}
								style={{
									marginTop: `calc(${cardHeight} / 4)`,
									marginBottom: `calc(${cardHeight} / 4)`,
									marginRight: 0,
									marginLeft: 0,
									// Border: '0px solid white',
									cursor: 'auto',
								}}
								className={'sample'}
							></CardView>)}
						</Container>
					</div>
				))}
			</ContainerNoBorderView>
		</div>
	);
}

export function SampleOverlayView({gameStateManager, style, onInfoToggle, sample}: TSampleOverlay): React.ReactElement {
	if (sampleCommunityCards.length === 0) {
		sampleCommunityCards = [
			...gameStateManager.state.communityCards.filter(c => !Card.UNKNOWN.equals(c)).map(c => Card.of(c.rank.rank + c.suit.suit)),
			...sample.community_cards.map(c => Card.of(c)),
		];
	}

	if (samplePlayers.length === 0) {
		samplePlayers = gameStateManager.state.players.map(p => {
			const samplePlayer = sample.players[p.id];
			const preassignedCards = Cards.nonUnknown(p.cards);

			if (samplePlayer !== undefined) {
				const sampleCards = samplePlayer.cards.map(c => Card.of(c));
				const playerCards = [...preassignedCards, ...sampleCards];
				return new Player({
					name: p.name,
					id: p.id,
					cards: playerCards,
				});
			}

			return new Player({
				name: p.name,
				id: p.id,
				cards: [...preassignedCards],
			});
		});
	}

	// If (sampleCommunityCards.length === 0) {
	//     sampleCommunityCards.push(...[
	//         ...gameStateManager.state.communityCards.filter(c => !Card.UNKNOWN.equals(c)).map(c => Card.of(c.rank.rank + c.suit.suit)),
	//         ...sample.communityCards.map(c => Card.of(c))
	//     ])
	// }
	//
	// if (samplePlayers.length === 0) {
	//     samplePlayers.push(...gameStateManager.state.players.map(p => {
	//         const player = sample.players[p.id];
	//         if (player !== undefined) {
	//             const sampleCards = player.cards.map(c => Card.of(c))
	//             return new Player({
	//                 name: p.name,
	//                 id: p.id,
	//                 cards: [...sampleCards]
	//             })
	//         } else {
	//             return new Player({
	//                 name: p.name,
	//                 id: p.id,
	//                 cards: []
	//             })
	//         }
	//     }))
	// }

	// samplePlayers.map(p => {
	//     const samplePlayer = sample.players[p.id]
	//     const preassignedCards: TCardValueOnly[] = gameStateManager.state.players
	//         .filter(pp => pp.id == p.id)[0]
	//         .cards
	//         .filter(c => !Card.UNKNOWN.equals(c))
	//         .map(c => {
	//             return c.rank.rank + c.suit.suit
	//     })
	//     const finalPlayerCards: TCardValueOnly[] = [...preassignedCards, ...(samplePlayer?.cards ?? [])]
	//
	//     p.cards.map((c, i) => {
	//         c.rank = new Rank(finalPlayerCards[i][0] as TRank)
	//         c.suit = new Suit(finalPlayerCards[i][1] as TSuit)
	//     })
	// })

	// sampleCommunityCards.map((c, i) => {
	//     const preassignedCommunityCards = gameStateManager.state.communityCards
	//         .filter(c => !Card.UNKNOWN.equals(c))
	//         .map(c => {
	//             return c.rank.rank + c.suit.suit
	//         })
	//     const sampleCommunityCards = sample.communityCards
	//     const finalCommunityCards = [...preassignedCommunityCards, ...sampleCommunityCards]
	//     if (finalCommunityCards[i] !== undefined) {
	//         c.rank = new Rank(finalCommunityCards[i][0] as TRank)
	//         c.suit = new Suit(finalCommunityCards[i][1] as TSuit)
	//     } else {
	//         c.rank = Rank.UNKNOWN
	//         c.suit = Suit.UNKNOWN
	//     }
	// })

	const [sampleState, setSampleState] = React.useState<TGameState>(GameState.getInitialGameState({
		players: samplePlayers,
		communityCards: sampleCommunityCards,
		gameStateResult: latestGameStateResult,
	}));

	const sampleStateManager = new GameState({state: sampleState, setState: setSampleState});

	React.useEffect(() => {
		if (gameStateManager.state.reason === 'poker chart clicked') {
			updateSampleGameStage(sampleStateManager)
				.catch(console.error);

			// If (sampleStateManager.hash() !== sampleStateManager.state.gameStateResult?.gameHash) {
			//     if (sampleStateManager.state.gameStateResult !== undefined) {
			//         sampleStateManager.state.gameStateResult.gameHash = sampleStateManager.hash()
			//     } else {
			//         sampleStateManager.state.gameStateResult = {
			//             gameHash: sampleStateManager.hash(),
			//             players: []
			//         }
			//     }
			//     console.log(666, sampleStateManager.hash())
			//     sampleStateManager.updateStateFields('saved in progress game hash', {})
			//         .catch(console.error)
			//
			//     updateSampleGameStage(sampleStateManager)
			//         .catch(console.error)
			// }
		}
	}, [gameStateManager.state.reason]);

	React.useEffect(() => {

		// If (sampleStateManager.state.gameStateResult?.gameHash !== sampleStateManager.hash()) {
		//     updateSampleGameStage(sampleStateManager)
		//         .catch(console.error)
		// }

		// if (SHOULD_UPDATE_SAMPLE) {
		//     console.log('sample changed', sampleState)
		//
		//     updateSampleGameStage(sampleStateManager)
		//         .catch(console.error)
		// }
		// SHOULD_UPDATE_SAMPLE = false;

		// fetched = false;
		// fetched = !sampleState.shouldRefreshGameState
	}, [...[
		sample,
		// ...Object.keys(sample.players).map(p_id => {
		//     const player = sample.players[p_id]
		//     return player.cards
		// }),
		// ...sample.communityCards
	]]);
	// ], SHOULD_UPDATE_SAMPLE, gameStateManager.state.shouldRefreshGameState])

	async function onXButtonClick() {
		await onInfoToggle();
		// Fetched = !fetched
		samplePlayers = [];
		sampleCommunityCards = [];
	}

	const boxWidthPct = 80;
	const boxHeightPct = 80;

	const shouldRender = true;// SampleStateManager.state.communityCards.filter(c => !c.equals(Card.UNKNOWN)).length === 5

	return <Overlay style={{...style, zIndex: 1}}>{
		<div id={'backgroundBlackOverlay'} style={
			{
				backgroundColor: 'rgba(0,0,0,.5)',
				height: '100%',
				width: '100%',
				justifyContent: 'center',
				display: 'flex',
				alignItems: 'center',
				cursor: 'default',
				userSelect: 'none',
			}}>
			<div style={{position: 'absolute', right: `${(100 - boxWidthPct) / 2}%`, top: `${(100 - boxHeightPct) / 2}%`}}>
				<XButton xButtonVisible={true} xButtonCallback={onXButtonClick}></XButton>
			</div>
			<div style={{
				width: `${boxWidthPct}%`,
				height: `${boxHeightPct}%`,
				overflowY: 'scroll',
				backgroundColor: 'var(--bw-secondary)',
				borderRadius: '25px',
				overflowX: 'clip',
				display: 'block',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
				{shouldRender && <SampleView sampleStateManager={sampleStateManager}></SampleView>}
			</div>
		</div>
	}</Overlay>;
}
