import React, {type CSSProperties, type ReactElement} from 'react';
import {Bar, BarChart, Legend, Tooltip, type TooltipProps, XAxis, YAxis} from 'recharts';
import {getLoadingText} from '../simulation-results-view';
import {strongToWeakColor} from '../../../colors';
import has = Reflect.has;
import {Container} from 'react-bootstrap';
import {type Player} from '../../../../poker/player';
import {type GameState} from '../../../../poker/game-state';
import {SampleOverlayView, setNeedToFetch} from '../../overlays/sample-overlay-view';
import _ from 'lodash';
import {
	SimulationRequestManager,
	type TCardValueOnly,
	type TProbabilityResponseSample,
} from '../../../../backend/current-state/simulation-request-manager';

export type TPokerBarChartInput<T = any> = {
	style?: CSSProperties;
	title: string | undefined;
	data: any[];
	width: number;
	height: number;
	y_axis_key: string;
	bars: Array<{
		dataKey: string;
		name: string;
		fill: string;
	}>;
	randomDataGenerator: (prev: T) => T;
	player?: Player;
	sampleGetter: (e: TooltipProps<any, any>, dataKey: string) => TProbabilityResponseSample | undefined;
	playerNameGetter: (e: TooltipProps<any, any>, s: TProbabilityResponseSample | undefined, player?: Player) => string;
	gameState: GameState;
	hasLegend?: boolean;
	hasBorder?: boolean;
};

function formatProbability(p: number) {
	return Math.round(p * 10000) / 100;
}

export type TProbabilityResponsePlayer = {
	name: string;
	cards: TCardValueOnly[];
};

let sample: TProbabilityResponseSample | undefined;
// Let players: TProbabilityResponsePlayer[];

export function BasePokerBarChartView({style = {}, title, data, width, height, y_axis_key, bars, sampleGetter, gameState, hasLegend = true, hasBorder = true}: TPokerBarChartInput): ReactElement {
	const [mostRecentHoverDataKey, setMostRecentHoverDataKey] = React.useState('');
	const [mostRecentHoverProps, setMostRecentHoverProps] = React.useState<TooltipProps<any, any>>();

	function customTooltip(e: TooltipProps<any, any>) {
		if (e.active && e.payload !== undefined && e.payload[0] !== null) {
			const hasBaseEventP = (e.payload[0].payload).base_event_p !== undefined;

			const labelMultiplier: number = e.payload.map(bar => bar.value as number).reduce((a, b) => a + b);

			return <div className='chart-tooltip'>
				{(
					<h6 style={{textAlign: 'center', color: 'var(--bw-secondary)'}}>
						{`${e.label as string}` + (!hasBaseEventP ? '' : ` (${formatProbability(e.payload[0].payload.base_event_p)}% chance)`)}
					</h6>
				)}
				{e.payload.map(bar => {
					// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
					const isHighlighted = _.get(mostRecentHoverProps, 'tooltipPayload[0].name') === bar.name;

					return (<div key={(bar.name as string) + (bar.value as string)} style={{color: isHighlighted ? 'var(--bw-secondary)' : 'var(--bw-middle)'}}>
						{`${bar.name as string}: ${formatProbability(bar.value / labelMultiplier)}%`}
					</div>);
				})}
			</div>;
		}

		return '';
	}

	async function onInfoToggle() {
		console.log('info clicked');
		await gameState.updateStateFields('info toggled bar chart', {
			isSampleOverlayVisible: !gameState.state.isSampleOverlayVisible,
		});
	}

	const header = getLoadingText() ?? title;

	style = {
		padding: 0,
		...style,
	};

	if (!hasBorder) {
		style.border = '0px solid white';
	}

	return (
		<div>
			{gameState.state.isSampleOverlayVisible
            && <SampleOverlayView sample={sample!} gameStateManager={gameState} onInfoToggle={onInfoToggle}
            ></SampleOverlayView>}
			<Container style={style}>
				<div className={'chart-container'}>
					{header !== undefined
                    && (<div><br></br><h4>{header}</h4></div>)
					}
					<BarChart
						data={data}
						width={width}
						height={height}
						layout='vertical'
						margin={{
							top: 20,
							right: 30,
							left: 20,
							bottom: 5,
						}}
						style={{
							width: `${width}px`,
							height: `${height}px`,
							color: 'var(--bw-primary)',
						}}
					>
						<Tooltip content={customTooltip}></Tooltip>
						<YAxis dataKey={y_axis_key} type={'category'} style={{stroke: 'transparent'}} tick={{fill: 'var(--bw-primary)'}}/>
						<XAxis type={'number'} tick={false} style={{stroke: 'transparent'}}></XAxis>
						{hasLegend && <Legend
							formatter={(value, entry, index) => <span style={{color: 'var(--bw-primary)'}}>{value}</span>}
						/>}
						{bars.map(b => {
							const fill = SimulationRequestManager.isSimulationInProgress ? strongToWeakColor(b.fill) : b.fill;
							return <Bar
								onMouseEnter={async e => {
									setMostRecentHoverDataKey(e.tooltipPayload[0].dataKey);
									setMostRecentHoverProps(e);
								}
								}
								style={{cursor: 'pointer'}}
								onClick={async e => {
									setNeedToFetch();

									sample = sampleGetter(mostRecentHoverProps!, mostRecentHoverDataKey);

									// Players = gameState.state.players.map(p => {
									// 	const preAssignedCards: TCardValueOnly[] = p.cards.filter(c => !c.equals(Card.UNKNOWN)).map(c => (c.rank.rank + c.suit.suit));
									// 	const cards: TCardValueOnly[] = [...preAssignedCards, ..._.get(sample, `players[${p.id}].cards`, [])];
									// 	return {
									// 		name: p.name,
									// 		cards,
									// 	};
									// });

									await gameState.updateStateFields('poker chart clicked', {
										isSampleOverlayVisible: true,
									});
								}}
								key={(title ?? '') + b.dataKey}
								dataKey={b.dataKey}
								name={b.name}
								stackId={'a'}
								fill={fill}></Bar>;
						})}
					</BarChart>
				</div>
			</Container>
		</div>
	);
}
