import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {GameState, type TGameState, setMostRecentGameState} from './poker/game-state';
import {GameViewDesktop} from './views/views/game/game-view-desktop';
import {Card, Rank, Suit} from './poker/card';
import {
	SimulationRequestManager,
} from './backend/current-state/simulation-request-manager';
import {fetchCurrentGameState} from './backend/current-state/fetch-current-game-state';
import {GameViewMobile} from './views/views/game/game-view-mobile';

export let selectedCard: Card | undefined;

export async function setSelectedCard(stateManager: GameState, c: Card) {
	selectedCard = c;
	await stateManager.updateStateFields('setSelectedCard called', {});
}

const pressedKeys = new Set<string>();

export async function updateSelectedCard(stateManager: GameState, newSuit?: Suit, newRank?: Rank) {
	let safeNewSuit: Suit;
	let safeNewRank: Rank;

	const card = stateManager.state.selectedCard;

	if (card !== undefined) {
		if (card.rank.equals(Rank.UNKNOWN) && newSuit !== undefined) {
			return;
		}

		if (newSuit) {
			safeNewRank = card.rank;
			safeNewSuit = newSuit;
		}

		if (newRank) {
			safeNewRank = newRank;
			safeNewSuit = card.suit;
		}

		if (newSuit && newRank) {
			safeNewSuit = newSuit;
			safeNewRank = newRank;
		}

		const potentialNewCard = new Card({suit: safeNewSuit!, rank: safeNewRank!, id: card.id});

		if (stateManager.cardIsPresent(potentialNewCard)) {
			if (!potentialNewCard.equals(Card.UNKNOWN)) {
				stateManager.incrementSuit(potentialNewCard);
				if (stateManager.cardIsPresent(potentialNewCard)) {
					console.log(`Not updating card to ${potentialNewCard.toString(false)} because card is already present!`);
					return;
				}

				safeNewSuit = potentialNewCard.suit;
			}
		}

		console.log(`Updating ${card.toString(false)} to ${potentialNewCard.toString(false)}`);

		card.rank = safeNewRank!;
		card.suit = safeNewSuit!;

		stateManager.assignUnknownCardSuits(stateManager.getAllCards());
		stateManager.assignUnknownCardRanks(stateManager.getAllCards());
	}
}

// Async function onKeyUp(e: any) {
// 	const key = e.key.toLowerCase();
// 	pressedKeys.delete(key);
// }

async function onKeyPress(stateManager: GameState, e: any) {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
	const key = e.key.toLowerCase() ?? '';
	console.log('key pressed', key);

	if (key === 'escape') {
		if (stateManager.state.selectedCard !== undefined) {
			console.log('Escape key pressed');
			await stateManager.updateStateFields('on key press escape', {
				selectedCard: undefined,
				selectedCardIndex: undefined,
				hoveringCardPickerHidden: true,
			});
		}

		if (stateManager.state.isInfoOverlayVisible || stateManager.state.isSampleOverlayVisible) {
			await stateManager.updateStateFields('on key press escape', {
				isInfoOverlayVisible: false,
				isSampleOverlayVisible: false,
			});
		}
	} else if (key === 'tab') {
		// Const reverseTab = pressedKeys.has('shift')
		// await stateManager.selectNextCard(false)
	} else if (key === 'arrowright') {
		if (stateManager.state.selectedCard) {
			stateManager.incrementSuit(stateManager.state.selectedCard);
			await stateManager.updateStateFields('arrowright suit changed', {
				shouldRefreshGameState: true,
			});
		}
	} else if (key === 'arrowleft') {
		if (stateManager.state.selectedCard) {
			stateManager.incrementSuit(stateManager.state.selectedCard, true);
			await stateManager.updateStateFields('arrowleft suit changed', {
				shouldRefreshGameState: true,
			});
		}
	} else if (key === 'arrowup') {
		if (stateManager.state.selectedCard) {
			stateManager.incrementRank(stateManager.state.selectedCard);
			await stateManager.updateStateFields('arrowup rank changed', {
				shouldRefreshGameState: true,
			});
		}
	} else if (key === 'arrowdown') {
		if (stateManager.state.selectedCard) {
			stateManager.incrementRank(stateManager.state.selectedCard, true);
			await stateManager.updateStateFields('arrowdown rank changed', {
				shouldRefreshGameState: true,
			});
		}
	} else if (key === 'u' || key === 'backspace') {
		await updateSelectedCard(stateManager, Card.UNKNOWN.suit, Card.UNKNOWN.rank);
		await stateManager.updateStateFields('unselecting card after unknown entered', {
			selectedCard: undefined,
			shouldRefreshGameState: true,
		});
	} else if (Suit.VALID_INPUTS.has(key)) {
		await updateSelectedCard(stateManager, new Suit(key), undefined);
		await stateManager.updateStateFields('update selected card', {});
		await stateManager.updateStateFields('suit keydown', {
			shouldRefreshGameState: true,
		})
			.catch(console.error);
	} else if (Rank.VALID_INPUTS.has(key)) {
		await updateSelectedCard(stateManager, undefined, new Rank(key));
		await stateManager.updateStateFields('update selected card', {});
		await stateManager.updateStateFields('rank keydown', {
			shouldRefreshGameState: true,
		})
			.catch(console.error);
	}

	pressedKeys.add(key);
}

let localState: GameState | undefined;

export default function App() {
	const [state, setState] = React.useState<TGameState>(GameState.getInitialGameState());
	const stateManager = new GameState({state, setState});

	async function keyDown(e: any) {
		await onKeyPress(localState!, e);
	}

	// Async function initialize() {
	//     console.log('updating game state from initialization')
	//     console.log('Running initial simulation')
	//     document.removeEventListener('keydown', keyDown)
	//     document.addEventListener('keydown', keyDown)
	//     console.log('555555')
	//
	//     await stateManager.updateStateFields('initializing app', {
	//         shouldRefreshGameState: true
	//     }, false)
	// }

	async function refresh() {
		if (state.shouldRefreshGameState) {
			console.log('refresh');
			await fetchCurrentGameState(stateManager);

			await SimulationRequestManager.sendSimulation({stateManager})
				.then(async result => {
					if (result !== undefined) {
						await stateManager.updateStateFields('onSimulationResult callback', {
							simulationResult: result,
							simulationPhase: 'completed',
							simulationProgress: 1,
						});
					}
				})
				.catch(e => {
					console.error('Error during sendSimulation', e);
				});
		}
	}

	React.useEffect(() => {
		// Called when state changes

		selectedCard = state.selectedCard;
		localState = stateManager;
		setMostRecentGameState(state);
		console.log(`state changed (reason: ${state.reason ?? ''})`);

		refresh()
			.catch(console.error);
	}, [state]);

	React.useEffect(() => {
		console.log('App mounted');
		document.removeEventListener('keydown', keyDown);
		document.addEventListener('keydown', keyDown);
	}, []);

	const minDeviceWidthDesktop = 1262;

	const isMobile = false; // document.getElementById('root')!.clientWidth <= minDeviceWidthDesktop;

	async function onMediaQueryChange() {
		if (isMobile !== stateManager.state.isMobile) {
			await stateManager.updateStateFields(`setting isMobile: ${isMobile ? 'true' : 'false'}`, {
				isMobile,
			});
		}
	}

	onMediaQueryChange()
		.catch(console.error);

	return (
		<div>
			{!isMobile && (
				<div className='App'
					 style={{height: '100%', width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center'}}
				>
					<GameViewDesktop stateManager={stateManager}></GameViewDesktop>
				</div>
			)}
			{isMobile && (
				<div className='App'
					 style={{height: '100%', width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center'}}
				>
					<GameViewMobile stateManager={stateManager}></GameViewMobile>
				</div>
			)}
		</div>

	);
}
