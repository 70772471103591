import {type GameState} from '../../../poker/game-state';
import React from 'react';
import {WinLoseTieChart} from './charts/win-lose-tie-chart-view';
import {PlayerHandWinRateChartView} from './charts/player-hand-win-rate-chart-view';
import {ContainerNoBorderView} from '../containers/container-no-border-view';
import {MultiSelectView} from '../menus/multi-select-view';
import {type TTabMenuOption} from '../../components/tab-menu';
import {SimulationRequestManager} from '../../../backend/current-state/simulation-request-manager';

export function getLoadingText(): string | undefined {
	// TODO (not here) need to cancel old updates on new update
	return SimulationRequestManager.isSimulationInProgress ? 'Simulation In Progress...' : undefined;
}

export const TABLE_STATS = 'Table Stats';

export let selectedGraphView: string = TABLE_STATS;

// Create your forceUpdate hook
function useForceUpdate() {
	const [value, setValue] = React.useState(0); // Integer state
	return () => {
		setValue(value => value + 1);
	}; // Update state to force render
	// An function that increment 👆🏻 the previous state like here
	// is better than directly setting `value + 1`
}

export function SimulationResultsView({stateManager}: {stateManager: GameState}) {
	const elRef = React.useCallback((node: HTMLDivElement) => {
		async function saveSimulationGraphMargin() {
			if (node !== null) {
				if (node.clientHeight !== stateManager.state.simulationGraphMargin && node.clientHeight !== 0) {
					console.log('updating graph margin to', node.clientHeight);
					await stateManager.updateStateFields('simulation graph margin changed', {
						simulationGraphMargin: node.clientHeight,
						shouldRefreshGameState: false,
					});
				}
			}
		}

		saveSimulationGraphMargin()
			.catch(console.error);
	}, []);
	let moe = getLoadingText() ?? `±${Math.round((stateManager.state.simulationResult?.moe ?? 0) * 10000) / 100}%`;

	if (SimulationRequestManager.simulationStatus !== undefined) {
		moe = 'Simulation ' + SimulationRequestManager.simulationStatus;
	}

	const resultTabOptions: TTabMenuOption[] = [
		{
			name: 'Table Stats',
			content: (
				<div className={'result-container'} style={{alignContent: 'center', paddingTop: '2%'}}>
					<div ref={TABLE_STATS === selectedGraphView ? elRef : undefined} >
						<br></br>
						<div>Margin of Error: <strong>{moe}</strong></div>
						<br></br>
					</div>
					<WinLoseTieChart stateManager={stateManager}></WinLoseTieChart>
				</div>
			),
			visible: true,
		},
		...stateManager.state.players.map(p => {
			const name = p.name + ' Stats';
			return {
				name: `${p.name} Stats`,
				content: (
					<div key={'simulationResult' + p.id} className={'result-container'} style={{alignContent: 'center', paddingTop: '2%'}}>
						<div ref={name === selectedGraphView ? elRef : undefined}>
							<br></br>
							<div>Margin of Error: <strong>{moe}</strong></div>
							<br></br>
							{/* <PlayerWinRateBarView stateManager={stateManager} player={p}></PlayerWinRateBarView> */}
						</div>
						<PlayerHandWinRateChartView stateManager={stateManager} targetPlayer={p}></PlayerHandWinRateChartView>
					</div>
				),
				visible: true,
			};
		}),
	];

	const forceUpdate = useForceUpdate();

	return (
		<div className='box box-default simulation-results-view'>
			<h3 style={{
				padding: '20px',
			}}>Simulation Results</h3>
			{(
				<ContainerNoBorderView style={{padding: 0, width: '100%'}}>
					<MultiSelectView stateManager={stateManager} id={'simulation-result-multiselect'} options={resultTabOptions} onOptionChanged={async o => {
						selectedGraphView = o;
						forceUpdate();
					}} style={{paddingTop: '20px', width: '100%'}}></MultiSelectView>
					{/* <TabMenu style={{marginTop: `${stateManager.state.simulationGraphMargin}px`}} id={'resultsTabMenu'} options={resultTabOptions} onOptionChanged={async (o) => { */}
					{/*    // await stateManager.updateStateFields({activeGraphKey: o}) */}
					{/*    selectedGraphView = o */}
					{/*    forceUpdate() */}
					{/* }}> */}
					{/* </TabMenu> */}
					{/* <TabMenu style={{marginTop: `${stateManager.state.simulationGraphMargin}px`}} id={'resultsTabMenuPlayers'} options={secondMenuOptions} onOptionChanged={async (o) => { */}
					{/*    await stateManager.updateStateFields({activeGraphKey: o}) */}
					{/* }}> */}
					{/* </TabMenu> */}
				</ContainerNoBorderView>

			)}
		</div>
	);
}
