import {type GameState} from '../../../poker/game-state';
import {Card, Cards} from '../../../poker/card';
import {Container} from 'react-bootstrap';
import {MiniCardsView} from './mini-cards-view';
import React, {type CSSProperties} from 'react';
import {RedButton} from '../../components/red-button';

export let CARD_PICKER_HEIGHT = 406;
export const CARD_PICKER_ID = 'card_picker_box ';

export let lastCardPickerBoxBounds: DOMRect | undefined;

type BoundaryPadding = {
	top?: number;
	bottom?: number;
	left?: number;
	right?: number;
};
export function eventWasInsideBound(event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLElement>, bounds?: DOMRect, boundaryPadding?: BoundaryPadding) {
	if (bounds === undefined) {
		return false;
	}

	boundaryPadding ??= {};

	boundaryPadding.top ??= 0;
	boundaryPadding.bottom ??= 0;
	boundaryPadding.left ??= 0;
	boundaryPadding.right ??= 0;

	if ((event.clientY + boundaryPadding.top) < bounds.top) {
		// event is over top edge
		return false;
	}

	if ((event.clientY - boundaryPadding.bottom) >= bounds.bottom) {
		// event is over bottom edge
		return false;
	}

	if ((event.clientX + boundaryPadding.left) < bounds.left) {
		// event is over left edge
		return false;
	}

	if ((event.clientX - boundaryPadding.right) >= bounds.right) {
		// event is over right edge
		return false;
	}

	// event is NOT over any edge
	return true;
}

export function CardPickerBox({stateManager, style, hidden}: {stateManager: GameState; style: any; hidden: boolean}) {
	const known_cards = stateManager.knownCards();
	const cards = Cards.all()
		.map(c => {
			if (known_cards.has(c.toString(false))) {
				return Card.UNKNOWN;
			}

			return c;
		});

	const suit_1 = cards.slice(0, 13);
	const suit_2 = cards.slice(13, 26);
	const suit_3 = cards.slice(26, 39);
	const suit_4 = cards.slice(39, 52);

	async function onCardClicked(c: Card) {
		stateManager.state.selectedCard!.suit = c.suit;
		stateManager.state.selectedCard!.rank = c.rank;

		await stateManager.updateStateFields('card picker card clicked!', {
			shouldRefreshGameState: true,
		});
		await stateManager.selectNextCard(false);
	}

	const ref = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (ref.current?.clientHeight !== undefined
            && ref.current.clientHeight !== 0) {
			CARD_PICKER_HEIGHT = ref.current.clientHeight;
		}
	}, [ref.current?.clientHeight]);

	React.useEffect(() => {
		if (ref.current !== null) {
			lastCardPickerBoxBounds = ref.current.getBoundingClientRect();
		}
	}, [stateManager.state.selectedCard]);

	const miniCardContainerStyle = {
		backdropFilter: 'none',
		backgroundColor: 'transparent',
	};

	return (
		<div style={{height: 'fit-content', width: 'fit-content'}} id={CARD_PICKER_ID}
			 onMouseLeave={async e => {
				if (stateManager.state.selectedCard !== undefined) {
					const boxBounds = ref.current?.getBoundingClientRect();

					if (boxBounds !== undefined) {
						const adjacentCardBounds = document.getElementById(stateManager.state.selectedCard.id)!.getBoundingClientRect();

						const overCurrCardBox = eventWasInsideBound(e, {
							...boxBounds,
							top: adjacentCardBounds.top,
							bottom: adjacentCardBounds.bottom,
							height: adjacentCardBounds.height,
							left: boxBounds.left,
							right: boxBounds.right,
							width: boxBounds.width,
						}, {
							left: 20,
						});

						// const adjacentLeftToCardBox = eventWasInsideBound(e, adjacentCardBounds, {
						// 	right: 20,
						// });

						if (!overCurrCardBox) {
							await stateManager.updateStateFields('card picker box hover ended, unselecting card', {
								selectedCard: undefined,
								hoveringCardPickerHidden: true,
								// HoveringCardPickerHidden: false
							});
						} else {
							console.log('Skipping card picker box hover end because event is over current or last box position');
						}
					}
				}
			}}
		>
			<Container hidden={hidden} style={{
				// FlexDirection: 'column',
				position: 'absolute',
				width: 'fit-content',
				height: 'fit-content',
				zIndex: '1000',
				padding: 0,
				transition: 'all 500ms',
				backgroundColor: 'var(--bw-primary-background-opacity)',
				backdropFilter: 'var(--blur-strong)',
				...style,
			} as CSSProperties}>
				<div ref={ref} style={{position: 'relative', padding: '10px'}}>
					<div>
						{/* <RedButton style={{position: 'relative', transform: 'none'}} xButtonVisible={!Card.UNKNOWN.equals(stateManager.state.selectedCard)}></RedButton> */}
						<RedButton
							isVisible={!Card.UNKNOWN.equals(stateManager.state.selectedCard)}
							style={{position: 'relative', transform: 'none', padding: '4px'}}
							onClick={async () => {
								await onCardClicked(Card.UNKNOWN);
							}}
						>Clear Card</RedButton>
					</div>
					<div>
						<MiniCardsView cards={suit_1} onCardClicked={onCardClicked} containerKey={'card-picker-box'} containerStyle={miniCardContainerStyle}></MiniCardsView>
					</div>
					<div>
						<MiniCardsView cards={suit_2} onCardClicked={onCardClicked} containerKey={'card-picker-box'} containerStyle={miniCardContainerStyle}></MiniCardsView>
					</div>
					<div>
						<MiniCardsView cards={suit_3} onCardClicked={onCardClicked} containerKey={'card-picker-box'} containerStyle={miniCardContainerStyle}></MiniCardsView>
					</div>
					<div>
						<MiniCardsView cards={suit_4} onCardClicked={onCardClicked} containerKey={'card-picker-box'} containerStyle={miniCardContainerStyle}></MiniCardsView>
					</div>
				</div>
			</Container>
		</div>
	);
}
