import {type GameState} from '../../../../poker/game-state';
import React from 'react';
import {BasePokerBarChartView} from './base-poker-bar-chart-view';
import {GREEN_STRONG, RED_STRONG, YELLOW_STRONG} from '../../../colors';
import _ from 'lodash';
import {type TProbabilityResponseSample} from '../../../../backend/current-state/simulation-request-manager';

export function WinLoseTieChart({stateManager}: {stateManager: GameState}) {
	let data = [];
	const {simulationResult} = stateManager.state;
	if (simulationResult !== undefined) {
		for (const player_id of Object.keys(simulationResult.players)) {
			const player_result = simulationResult.players[player_id];
			const player = stateManager.state.players.filter(p => p.id === player_id)[0];
			if (player !== undefined) {
				data.push({
					name: player.name,
					error: player_result.probability.win.moe,
					p_win: player_result.probability.win.p,
					p_lose: player_result.probability.lose.p,
					p_tie: player_result.probability.tie.p,
					samples: {
						p_win: player_result.probability.win.sample,
						p_lose: player_result.probability.lose.sample,
						p_tie: player_result.probability.tie.sample,
					},
				});
			}
		}
	}

	if (data.length === 0) {
		data = stateManager.state.players.map(player => ({
			name: player.name,
		}));
	}

	return (
		<BasePokerBarChartView
			title={'Win/Tie/Lose Probability'}
			data={data}
			width={600}
			height={300}
			y_axis_key={'name'}
			bars={
				[
					{name: 'Wins', dataKey: 'p_win', fill: GREEN_STRONG},
					{name: 'Chops Pot', dataKey: 'p_tie', fill: YELLOW_STRONG},
					{name: 'Loses', dataKey: 'p_lose', fill: RED_STRONG},
				]}
			randomDataGenerator={oldData => {
				const random_p = Math.round(Math.random() * 100) / 100;
				return {
					name: oldData.name as string,
					error: Math.round(random_p * 0.01 * 100) / 100,
					p_win: random_p,
					p_lose: 1 - random_p - 0.01,
					p_tie: 0.01,
				};
			}}
			sampleGetter={function (e, dataKey) {
				// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
				return _.get(e, 'samples.' + dataKey, undefined) as unknown as TProbabilityResponseSample;
			}}
			playerNameGetter={(e, s, p) => _.get(e, 'name', '')}
			gameState={stateManager}
		></BasePokerBarChartView>
	);
}
