import {type Player} from '../../../poker/player';
import React, {type CSSProperties} from 'react';
import {ContainerNoBorderView} from '../containers/container-no-border-view';
import {CardView} from '../card/card-view';
import {type Card} from '../../../poker/card';

export function playerToDisplayName(player: Player) {
	return player.name + (!player.isWinner ? ': (Behind)' : player.isTie ? ': (Tied Winner)' : ' (Ahead)');
}

export const MINI_CARD_HEIGHT = 75;
export const MINI_CARD_ASPECT_RATIO = 336 / 240;
export const MINI_CARD_WIDTH = MINI_CARD_HEIGHT / MINI_CARD_ASPECT_RATIO;
export const MINI_CARD_PADDING = MINI_CARD_WIDTH / 5;

export function MiniCardsView({cards, onCardClicked, containerKey, cardStyle, containerStyle, title}:
{
	cards: Card[];
	onCardClicked: (card: Card) => Promise<void>;
	containerKey: string;
	cardStyle?: CSSProperties;
	containerStyle?: CSSProperties;
	title?: string;
}) {
	const lastLeftMargin = (-MINI_CARD_WIDTH + MINI_CARD_PADDING);
	let isFirst = true;

	cardStyle = {
		padding: 0,
		overflow: 'hidden',
		borderRadius: 'unset',
		marginRight: 0,
		marginTop: 0,
		marginBottom: 0,
		...(cardStyle ?? {}),
	};

	const [lastHoverI, setLastHoverI] = React.useState<number>(Infinity);

	return <div style={{float: 'left', padding: MINI_CARD_PADDING, ...containerStyle}}>
		{title !== undefined && <div style={{whiteSpace: 'nowrap', padding: '10px'}}>{title}</div>}
		<ContainerNoBorderView style={{width: 'fit-content', margin: 0, height: `${MINI_CARD_HEIGHT}px`, padding: 0, backgroundColor: 'var(--bw-primary-background-opacity)'}}>
			{cards.map((card, cardI) => {
				const card_key = containerKey + '_mini_card_' + card.id;
				const isDim = cardI > lastHoverI && cardI < (lastHoverI + 4);
				const isHovered = cardI === lastHoverI;

				const cardStyleInstance = {
					height: 'fit-content',
					width: 'fit-content',
					overflow: 'visible',
					// Opacity: isDim ? '0%' : '100%',
					...cardStyle,
					marginLeft: isFirst ? 0 : `${lastLeftMargin}px`,
					marginTop: `${(isHovered ? -MINI_CARD_HEIGHT / 5 : 0)}px`,
					zIndex: (isHovered ? 2 : 1),
				};

				isFirst = false;

				return <CardView
					needsRef={false}
					className={'card-button-mini'}
					id={`card_outs_${cardI}`}
					height={MINI_CARD_HEIGHT}
					width={MINI_CARD_WIDTH}
					key={`${card_key}_${cardI}`}
					card={card}
					onClick={async c => {
						await onCardClicked(c);
					}}
					style={cardStyleInstance}
					onHoverStart={async () => {
						setLastHoverI(cardI);
					}}
					onHoverEnd={async () => {
						setLastHoverI(Infinity);
					}
					}

				></CardView>;
			})}
		</ContainerNoBorderView>
	</div>;
}
