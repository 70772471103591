import {type Player} from '../../../poker/player';
import {type GameState} from '../../../poker/game-state';
import React from 'react';
import {Card} from '../../../poker/card';
import {type TCardValueOnly, type TCurrentStateMappedPlayer} from '../../../backend/current-state/simulation-request-manager';
import {Container} from 'react-bootstrap';
import {MiniCardsView} from '../card/mini-cards-view';

export function OutsView({player, stateManager, onOutClicked}:
{
	player: Player;
	stateManager: GameState;
	onOutClicked: (card: Card) => Promise<void>;
}) {
	const gameStatePlayer: TCurrentStateMappedPlayer | undefined = stateManager.state.gameStateResult?.players.filter(p => p.id === player.id)[0];

	const outs: Array<{
		name: string;
		card_outs: TCardValueOnly[];
	}> = gameStatePlayer?.outs.map(out => ({
		name: out.name,
		card_outs: out.cards,
	})) ?? [];

	// Const playerHasNoCards = false//player.cards[0].equals(Card.UNKNOWN) && player.cards[1].equals(Card.UNKNOWN);

	if (outs.length > 0) {
		return <Container style={
			{
				float: 'left',
				width: '100%',
				paddingTop: '5%',
				marginTop: '5%',
				backgroundColor: 'transparent',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}
		}>
			<div>
				<div style={{textAlign: 'center', width: '100%'}}>
					{<h5 style={{whiteSpace: 'nowrap'}}>{`${outs.map(o => o.card_outs).flat().length} Outs:`}</h5>}
				</div>
				{outs.map(out => {
					const outKey = 'out_' + out.name;

					const key = out.card_outs.join(',') + '_card_stack';

					const title = out.name + ` Outs (${out.card_outs.length})`;

					return (
						<div key={'container_' + outKey}
							style={
								{
									width: '100%',
									margin: 0,
									padding: '10px',
									// JustifyContent: 'space-between',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}
							}>
							{//! playerHasNoCards &&
								<div
									style={
										{
											padding: '0',
											backgroundColor: 'var(--bw-primary-background-opacity)',
											// BackdropFilter: 'var(--blur-strong)',
											borderRadius: '12px',
											width: 'fit-content',
											height: 'fit-content',
											marginTop: 0,
										}}>
									<MiniCardsView
										title={title}
										cards={out.card_outs.map(c => Card.of(c))} onCardClicked={async c => {
											await onOutClicked(c);
										}
										}
										containerKey={key}
										containerStyle={{
											backgroundColor: 'transparent',
											paddingTop: 0,
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',

										}}
									></MiniCardsView>
								</div>
							}
						</div>
					);
				})}
			</div>
		</Container>;
	}

	return <div></div>;
}
