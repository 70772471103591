import {type Card, Cards, type TRank, type TSuit} from '../../poker/card';
import {type GameState} from '../../poker/game-state';
import {selectedCard} from '../../App';
import {type Player} from '../../poker/player';
import {SimulationRequestManager} from './simulation-request-manager';

const ENDPOINT = 'https://api.sandbox.montepython.app/v1/state';

export const ALL_HANDS_RANKED = [
	'Royal Flush',
	'Straight Flush',
	'Four Of A Kind',
	'Full House',
	'Flush',
	'Straight',
	'Three Of A Kind',
	'Two Pair',
	'One Pair',
	'High Card',
];

export type TCard = {
	id: string;
	value: string;
	// Rank: TRank,
	// suit: TSuit
};

export type TCurrentStateRequest = {
	request_id: string;
	players: Array<{
		id: string;
		cards: TCard[];
	}>;
	community_cards: TCard[];
};

export type TCardNoId = {
	rank: TRank;
	suit: TSuit;
};

export type TCardIdOnly = {
	id: string;
};

export type TCardValueOnly = string;

export type TCurrentStatePlayerResponse = {
	id: string;
	winner: boolean;
	outs: Record<string, TCardValueOnly[]>;
	hand: {
		cards: {
			cards_in_hand: TCardIdOnly[];
			cards_not_in_hand: TCardIdOnly[];
		};
		name: string;
	};
};

export type TCurrentStateResponse = {
	players: TCurrentStatePlayerResponse[];
};

export type TCurrentStateMappedOut = {
	name: string;
	cards: TCardValueOnly[];
};

export type TCurrentStateMappedPlayer = (Omit<TCurrentStatePlayerResponse, 'outs'> & {

	outs: TCurrentStateMappedOut[];
});

export type TCurrentStateMappedResponse = {
	players: TCurrentStateMappedPlayer[];
	gameHash: string;
};

export type TFetchCurrentGameStateManager = {
	state: {
		players: Player[];
		community_cards: Card[];
		playerIdToPlayer: Record<string, Player>;
		cardIdToCard: Record<string, Card>;
	};
	getAllCards: () => Card[];
};

async function processStateResponse(stateManager: GameState, res: TCurrentStateResponse, gameHash: string): Promise<TCurrentStateMappedResponse> {
	stateManager.getAllCards().map(c => {
		c.inWinningHand = false;
		c.inWinningHandSecondary = false;
	});

	const isTie = res.players.filter(p => p.winner).length > 1;

	res.players.map(p => {
		const player = stateManager.state.playerIdToPlayer[p.id];
		player.isWinner = p.winner;
		player.isTie = p.winner && isTie;
		player.handName = p.hand.name;

		if (p.winner) {
			p.hand.cards.cards_in_hand.map(c => {
				const card = stateManager.state.cardIdToCard[c.id];
				// Console.log('card', card)
				card.inWinningHand = true;
			});
			p.hand.cards.cards_not_in_hand.map(c => {
				const card = stateManager.state.cardIdToCard[c.id];
				card.inWinningHandSecondary = true;
			});
		}
	});

	const mapped: TCurrentStateMappedResponse = {
		players: res.players.map(p => ({
			...p,
			outs: ALL_HANDS_RANKED
				.filter(hand_name => p.outs[hand_name] !== undefined)
				.map(hand_name => ({
					name: hand_name,
					cards: p.outs[hand_name],
				})),
		})),
		gameHash,
	};

	await stateManager.updateStateFields('fetchCurrentGameState finished', {
		gameStateResult: mapped,
		selectedCard,
		shouldRefreshGameState: false,
	});

	return Promise.resolve(mapped);
}

export async function fetchCurrentGameState(gameStateManager: GameState): Promise<TCurrentStateMappedResponse> {
	console.log('fetching current game state');
	const body: TCurrentStateRequest = {
		request_id: SimulationRequestManager.generateId(),
		players: gameStateManager.state.players.map(p => ({
			id: p.id,
			cards: Cards.nonUnknown(p.cards).map(c => ({
				id: c.id,
				value: c.rank.rank + c.suit.suit,
			})),
		})),
		community_cards: Cards.nonUnknown(gameStateManager.state.communityCards).map(c => ({
			id: c.id,
			value: c.rank.rank + c.suit.suit,
		})),
	};

	const gameHash = gameStateManager.hash();

	const res: TCurrentStateResponse = await fetch(ENDPOINT, {
		method: 'POST',
		headers: {'content-type': 'application/json'},
		body: JSON.stringify(body),
	})
		.then(async r => await r.json() as TCurrentStateResponse);

	// Const res = await axios.post(ENDPOINT, body)
	// const res = await axios({
	//     method: 'post',
	//     headers: {
	//         'content-type': 'application/json'
	//     },
	//     url: ENDPOINT,
	//     data: body
	// })

	return processStateResponse(gameStateManager, res, gameHash);

	// GameStateManager.getAllCards().map(c => {
	//     //     c.inWinningHand = false;
	//     //     c.inWinningHandSecondary = false;
	//     // })
	//
	// const isTie = res.players.filter(p => p.winner).length > 1;
	//
	// res.players.map(p => {
	//     const player = gameStateManager.state.playerIdToPlayer[p.id]
	//     player.isWinner = p.winner
	//     player.isTie = p.winner && isTie
	//     player.handName = p.hand.name
	//
	//     if (p.winner) {
	//         p.hand.cards.cards_in_hand.map(c => {
	//             const card = gameStateManager.state.cardIdToCard[c.id];
	//             // console.log('card', card)
	//             card.inWinningHand = true;
	//         })
	//         p.hand.cards.cards_not_in_hand.map(c => {
	//             const card = gameStateManager.state.cardIdToCard[c.id];
	//             card.inWinningHandSecondary = true;
	//         })
	//     }
	// })
	//
	// const sorted_outs: TCurrentStateMappedOut[] = []
	//
	// res.players.map(p => {
	//     return {
	//         ...p,
	//         outs: ALL_HANDS_RANKED.map(hand_name => {
	//             const maybeOut = p.outs[hand_name]
	//             if (maybeOut !== undefined) {
	//                 sorted_outs.push({
	//                     name: hand_name,
	//                     cards: maybeOut
	//                 })
	//             }
	//         })
	//     }
	// })
	//
	// const mapped: TCurrentStateMappedResponse = {
	//     players: res.players.map(p => {
	//         return {
	//             ...p,
	//             outs: ALL_HANDS_RANKED
	//                 .filter(hand_name => p.outs[hand_name] !== undefined)
	//                 .map(hand_name => {
	//                     return {
	//                         name: hand_name,
	//                         cards: p.outs[hand_name]
	//                     }
	//                 })
	//         }
	//     })
	// };
	//
	//
	// return Promise.resolve(mapped);
}
