import _ from 'lodash';
import {RED_STRONG} from '../views/colors';

export type TRank = 'u' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 't' | 'j' | 'q' | 'k' | 'a';
export type TSuit = 'u' | 's' | 'c' | 'd' | 'h';

export const CARD_MAP: Record<string, string> = {
	us: '_S',
	uc: '_C',
	ud: '_D',
	uh: '_H',
	'2u': '2_',
	'3u': '3_',
	'4u': '4_',
	'5u': '5_',
	'6u': '6_',
	'7u': '7_',
	'8u': '8_',
	'9u': '9_',
	tu: 'T_',
	ju: 'J_',
	qu: 'Q_',
	ku: 'K_',
	au: 'A_',
	'2s': '🂢',
	'3s': '🂣',
	'4s': '🂤',
	'5s': '🂥',
	'6s': '🂦',
	'7s': '🂧',
	'8s': '🂨',
	'9s': '🂩',
	ts: '🂪',
	js: '🂫',
	qs: '🂭',
	ks: '🂮',
	as: '🂡',
	'2h': '🂲',
	'3h': '🂳',
	'4h': '🂴',
	'5h': '🂵',
	'6h': '🂶',
	'7h': '🂷',
	'8h': '🂸',
	'9h': '🂹',
	th: '🂺',
	jh: '🂻',
	qh: '🂽',
	kh: '🂾',
	ah: '🂱',
	'2d': '🃂',
	'3d': '🃃',
	'4d': '🃄',
	'5d': '🃅',
	'6d': '🃆',
	'7d': '🃇',
	'8d': '🃈',
	'9d': '🃉',
	td: '🃊',
	jd: '🃋',
	qd: '🃍',
	kd: '🃎',
	ad: '🃁',
	'2c': '🃒',
	'3c': '🃓',
	'4c': '🃔',
	'5c': '🃕',
	'6c': '🃖',
	'7c': '🃗',
	'8c': '🃘',
	'9c': '🃙',
	tc: '🃚',
	jc: '🃛',
	qc: '🃝',
	kc: '🃞',
	ac: '🃑',
	uu: '🂠',
};

export class Suit {
	public static ALL: Suit[] = [
		new Suit('c'),
		new Suit('d'),
		new Suit('s'),
		new Suit('h'),
	];

	public static VALID_INPUTS: Set<string> = new Set<string>(['u', 'c', 'd', 's', 'h']);
	public static UNKNOWN = new Suit('u');

	// eslint-disable-next-line @typescript-eslint/parameter-properties
	public suit: TSuit;
	constructor(suit: TSuit) {
		this.suit = suit;
	}

	toString(): string {
		if (this.suit === 'u') {
			return '🂠';
		}

		if (this.suit === 's') {
			return '♠';
		}

		if (this.suit === 'c') {
			return '♣';
		}

		if (this.suit === 'd') {
			return '♦';
		}

		return '♥';
	}

	equals(other: Suit): boolean {
		return this.suit.toString() === other.suit.toString();
	}
}

export class Rank {
	public static ALL: Rank[] = [
		new Rank('2'),
		new Rank('3'),
		new Rank('4'),
		new Rank('5'),
		new Rank('6'),
		new Rank('7'),
		new Rank('8'),
		new Rank('9'),
		new Rank('t'),
		new Rank('j'),
		new Rank('q'),
		new Rank('k'),
		new Rank('a'),
	];

	public static VALID_INPUTS: Set<string> = new Set<string>(['u', '2', '3', '4', '5', '6', '7', '8', '9', 't', 'j', 'q', 'k', 'a']);
	public static UNKNOWN = new Rank('u');

	// eslint-disable-next-line @typescript-eslint/parameter-properties
	public rank: TRank;
	constructor(rank: TRank) {
		this.rank = rank;
	}

	toString(): string {
		return this.rank.toUpperCase();
	}

	equals(other: Rank): boolean {
		return this.rank.toString() === other.rank.toString();
	}
}

export class Card {
	static idCounter = 0;
	static UNKNOWN: Card = new Card({rank: Rank.UNKNOWN, suit: Suit.UNKNOWN});

	static getNextId() {
		const rv = this.idCounter;
		this.idCounter++;
		return `card_${rv}`;
	}

	static of(s: string) {
		if (s === null) {
			throw new Error('Cannot initialize Card.of: s is null');
		} else if (s.length !== 2) {
			throw new Error('Cannot initialize Card.of: s should have length 2');
		}

		return new Card({rank: new Rank(s[0] as TRank), suit: new Suit(s[1] as TSuit)});
	}

	public rank: Rank;
	public suit: Suit;
	public readonly id: string;
	public inWinningHand: boolean;
	public inWinningHandSecondary: boolean;

	constructor(o: {rank: Rank; suit: Suit; id?: string} | undefined) {
		if (o === undefined) {
			this.rank = new Rank('u');
			this.suit = new Suit('u');
			this.id = Card.getNextId();
		} else {
			this.rank = o.rank;
			this.suit = o.suit;
			this.id = o.id ?? Card.getNextId();
		}

		this.inWinningHand = false;
		this.inWinningHandSecondary = false;
	}

	get color_code(): string {
		if (this.suit.suit === 's' || this.suit.suit === 'c') {
			return 'black';
		}

		if (this.suit.suit === 'u' || this.rank.rank === 'u') {
			return 'gray';
		}

		return RED_STRONG;
	}

	toString(useSymbols = true) {
		const key = `${this.rank.rank}${this.suit.suit}`.toLowerCase();
		if (!useSymbols) {
			return key.toUpperCase();
		}

		return _.get(CARD_MAP, key, 'U');
	}

	equals(other?: Card): boolean {
		if (other === undefined) {
			return false;
		}

		return this.rank.equals(other.rank) && this.suit.equals(other.suit);
	}
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Cards {
	static of(...cards: string[]): Card[] {
		return cards.map(c => Card.of(c));
	}

	static all(except: Card[] = []) {
		const drawn_cards = new Set<string>(except.map(c => c.toString(false)));
		const rv: Card[] = [];

		Suit.ALL.map(s => {
			Rank.ALL.map(r => {
				const card = new Card({rank: r, suit: s});
				if (!drawn_cards.has(card.toString(false))) {
					rv.push(card);
				}
			});
		});

		return rv;
	}

	static padUnknownCards(cards: Card[], n: number) {
		const unknown_card_count = n - cards.length;
		const unknown_cards = Array(unknown_card_count).fill(null).map(() => new Card(undefined));
		return cards.concat(unknown_cards.slice(0, unknown_card_count));
	}

	static nonUnknown(cards: Card[]) {
		return cards.filter(c => c.rank.rank !== 'u' && c.suit.suit !== 'u');
	}

	static unknown(cards: Card[]) {
		return cards.filter(c => c.rank.rank === 'u' && c.suit.suit === 'u');
	}
}
