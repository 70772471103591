import {type GameState} from '../../../poker/game-state';
import {XButton} from '../../components/x-button';
import React, {type CSSProperties} from 'react';
import {Overlay} from '../../components/overlay';

export function InfoOverlayView({style, onInfoToggle}: {stateManager: GameState; style?: CSSProperties; onInfoToggle: () => Promise<void>}) {
	async function onXButtonClick() {
		await onInfoToggle();
	}

	const simulationText = 'Instructions etc...';

	const boxWidthPct = 60;
	const boxHeightPct = 80;

	return <Overlay style={style}>{
		<div id={'backgroundBlackOverlay'} style={
			{
				backgroundColor: 'rgba(0,0,0,.5)',
				height: '100%',
				width: '100%',
				justifyContent: 'center',
				display: 'flex',
				alignItems: 'center',
				cursor: 'default',
				userSelect: 'none',
			}}>
			<div style={{position: 'absolute', right: `${(100 - boxWidthPct) / 2}%`, top: `${(100 - boxHeightPct) / 2}%`}}>
				<XButton xButtonVisible={true} xButtonCallback={onXButtonClick}></XButton>
			</div>
			<div style={{
				width: `${boxWidthPct}%`,
				height: `${boxHeightPct}%`,
				backgroundColor: 'var(--bw-secondary)',
				borderRadius: '25px',
				overflowX: 'clip',
			}}>
				<div style={
					{
						color: 'black',
						textAlign: 'left',
						whiteSpace: 'pre-line',
						overflowY: 'scroll',
						overflowX: 'hidden',
						padding: '5%',
						borderRadius: '25px',
						height: '100%',
					}}
				>
					{simulationText}
				</div>
			</div>
		</div>
	}</Overlay>;
}
