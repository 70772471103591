import InfoIcon from '@mui/icons-material/Info';
import React, {type CSSProperties} from 'react';
import {Button} from 'react-bootstrap';
import {type GameState} from '../../../poker/game-state';
import {CardsView} from '../card/cards-view';
import {ContainerNoBorderView} from '../containers/container-no-border-view';
import {AddPlayerButtonView} from '../player/add-player-button-view';
import {PlayerView} from '../player/player-view';
import {COMMUNITY_CARDS_CONTAINER_ID, SCROLLABLE_PLAYER_CONTAINER_ID} from './game-view-desktop';

export function InputView({stateManager, onInfoToggle}: {stateManager: GameState; onInfoToggle: () => Promise<void>}) {
	const communityCardsStyle: CSSProperties = {};
	if (stateManager.state.isMobile) {
		communityCardsStyle.display = 'flex';
		communityCardsStyle.flexDirection = 'column';
		communityCardsStyle.marginLeft = '0';
	}

	return (
		<div className={'input-view'}>
			<ContainerNoBorderView style={{alignItems: 'center'}}>
				<h3 style={{padding: '0', margin: 0}}>Table Cards</h3>
				<Button className={'icon-button'} aria-label='info' onClick={onInfoToggle}>
					<InfoIcon>
					</InfoIcon>
				</Button>
			</ContainerNoBorderView>
			<ContainerNoBorderView id={COMMUNITY_CARDS_CONTAINER_ID} style={{width: 'fit-content'}}>
				<CardsView keyPrefix={'community_cards'}
						   stateManager={stateManager}
						   cards={stateManager.state.communityCards}
						   indexOffset={0}
						   style={communityCardsStyle}
				></CardsView>
			</ContainerNoBorderView>
			<ContainerNoBorderView style={
				{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'stretch',
					width: `${document.getElementById(COMMUNITY_CARDS_CONTAINER_ID)?.getBoundingClientRect().width ?? 0}px`,
				}
			}>
				<div id={SCROLLABLE_PLAYER_CONTAINER_ID} style={{
					width: '100%',
					overflowX: 'scroll',
					overflowY: 'hidden',
				}}>
					<ContainerNoBorderView style={{
						padding: 0,
						margin: 0,
						flex: 1,
						alignItems: 'top',
						width: 'fit-content',
					}}>
						{stateManager.state.players.map((player, i) => {
							let style: CSSProperties = {
								padding: 0,
								paddingLeft: 0,
								paddingRight: '20px',
							};
							if (i === stateManager.state.players.length - 1) {
								style = {
									...style,
									paddingRight: 0,
								};
							}

							return (<PlayerView style={style} playerIndex={i} stateManager={stateManager} key={player.id} player={player}></PlayerView>);
						})}
					</ContainerNoBorderView>
				</div>
				<AddPlayerButtonView stateManager={stateManager}></AddPlayerButtonView>
			</ContainerNoBorderView>
		</div>
	);
}
