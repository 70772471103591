import {Player} from './player';
import {Card, Cards, Rank, Suit} from './card';
import {TABLE_STATS} from '../views/views/simulation/simulation-results-view';
import {
	type TSimulationResponseProcessed,
	type TSimulationWebsocketStatusPhase,
} from '../backend/current-state/simulation-request-manager';
import {type TCurrentStateMappedResponse} from '../backend/current-state/fetch-current-game-state';
import {updateCardPickerBounds} from '../views/views/card/card-view';

export type TGameState = {
	players: Player[];
	communityCards: Card[];
	selectedCard?: Card;
	selectedCardIndex?: number;
	cardIdToCard: Record<string, Card>;
	playerIdToPlayer: Record<string, Player>;
	shouldRefreshGameState: boolean;
	simulationGraphMargin?: number;
	simulationResult?: TSimulationResponseProcessed;
	gameStateResult?: TCurrentStateMappedResponse;
	isApiCallUpdate: boolean;
	numSimulations: number;
	isInfoOverlayVisible: boolean;
	isSampleOverlayVisible: boolean;
	activeGraphKey: string;
	simulationProgress: number;
	simulationPhase: TSimulationWebsocketStatusPhase;
	reason?: string;
	hoveringCardPickerHidden: boolean;
	hoveringCardPickerMarginLeft: number;
	hoveringCardPickerMarginTop: number;
	hoveringCardPickerHeight: number;
	userClosedCardPicker: boolean;
	simulationInfoText: string;
	isMobile: boolean;
	// SimulationInfoWidth: number
};

export let MOST_RECENT_GAME_STATE: TGameState | undefined;

export function setMostRecentGameState(state: TGameState) {
	MOST_RECENT_GAME_STATE = state;
}

window.addEventListener('keydown', e => {
	if (['Space', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.code)) {
		if (MOST_RECENT_GAME_STATE?.selectedCard !== undefined) {
			e.preventDefault();
		}
	}
}, false);

const INITIAL_GAME_STATE: TGameState = {
	players: [
		// Player.of('Ellek', ['as', 'ac']),
		Player.of('Player 1', []),
		Player.of('Player 2', []),
	].map(p => {
		p.cards = Cards.padUnknownCards(p.cards, 2);
		return p;
	}),
	// Community_cards: Cards.padUnknownCards(Cards.of('ad', 'ah', 'ks'), 5),
	communityCards: Cards.padUnknownCards(Cards.of(), 5),
	selectedCard: undefined,
	selectedCardIndex: undefined,
	cardIdToCard: {},
	playerIdToPlayer: {},
	shouldRefreshGameState: true,
	simulationGraphMargin: undefined,
	simulationResult: undefined,
	isApiCallUpdate: false,
	numSimulations: 500,
	isInfoOverlayVisible: false,
	isSampleOverlayVisible: false,
	activeGraphKey: TABLE_STATS,
	simulationProgress: 0,
	simulationPhase: 'completed',
	reason: 'initial state!',
	hoveringCardPickerHidden: true,
	hoveringCardPickerMarginLeft: 0,
	hoveringCardPickerMarginTop: 0,
	hoveringCardPickerHeight: 0,
	userClosedCardPicker: false,
	simulationInfoText: 'In Progress',
	isMobile: false,
	// SimulationPhase:
};

export class GameState {
	static getInitialGameState(overrides: Partial<TGameState> = {}): TGameState {
		return {
			...INITIAL_GAME_STATE,
			...overrides,
		};
	}

	readonly state: TGameState;
	private readonly setState: (s: TGameState) => void;

	constructor(o: {state: TGameState; setState: (s: TGameState) => void}) {
		if (o.state.communityCards.length > 5) {
			throw new Error('Error: community cards cannot be longer than 5');
		}

		this.state = o.state;
		this.setState = o.setState;
		this.getAllCards().map(c => {
			this.state.cardIdToCard[c.id] = c;
		});
		this.state.players.map(p => {
			this.state.playerIdToPlayer[p.id] = p;
		});
	}

	getAllCards(): Card[] {
		return [
			...this.state.communityCards,
			...this.state.players.map(p => p.cards).flat(),
		];
	}

	knownCards(): Set<string> {
		return new Set<string>(this.getAllCards().map(c => c.toString(false)));
	}

	cardIsPresent(card: Card): boolean {
		return this.getAllCards().filter(c => c.equals(card)).length >= 1;
	}

	async updateStateFields(reason: string, o: Partial<TGameState> = {}, log = true) {
		// TODO: check if there are any field changes in request. If no changes, add parameter force=true and if force
		if (log) {
			console.log(`Updating state fields (reason: ${reason})`, o);
		}

		if (MOST_RECENT_GAME_STATE === undefined) {
			MOST_RECENT_GAME_STATE = this.state;
		}

		if (o.selectedCard !== undefined) {
			if (o.selectedCardIndex === undefined) {
				o.selectedCardIndex = this.getAllCards().indexOf(o.selectedCard);
			}
		}

		this.setState({
			...MOST_RECENT_GAME_STATE,
			isApiCallUpdate: false,
			shouldRefreshGameState: false,
			...o,
			reason,
		});
	}

	incrementSuit(card: Card, reverse = false) {
		const suits = Suit.ALL;
		let currI = 0;

		while (currI < 4) {
			const suit = suits[currI];
			if (suit.suit === card.suit.suit) {
				break;
			}

			currI++;
		}

		let nextI = (currI + 1) % 4;

		if (reverse) {
			nextI = (currI - 1) % 4;
		}

		while (nextI !== (currI)) {
			if (nextI < 0) {
				nextI += 4;
			}

			const suit = suits[nextI];
			const maybeNewCard = new Card({rank: card.rank, suit});
			if (!this.cardIsPresent(maybeNewCard) && suit.suit !== card.suit.suit) {
				console.log('incrementing suit', suit);
				card.suit = suit;
				break;
			}

			if (reverse) {
				nextI = (nextI - 1) % 4;
			} else {
				nextI = (nextI + 1) % 4;
			}
		}

		this.assignUnknownCardRanks([card]);
	}

	incrementRank(card: Card, reverse = false) {
		const ranks = Rank.ALL;
		let currI = 0;

		while (currI < 13) {
			const rank = ranks[currI];
			if (rank.rank === card.rank.rank) {
				break;
			}

			currI++;
		}

		let nextI = (currI + 1) % 13;

		if (reverse) {
			nextI = (currI - 1) % 13;
		}

		while (nextI !== (currI)) {
			if (nextI < 0) {
				nextI += 13;
			}

			const rank = ranks[nextI];
			const maybeNewCard = new Card({rank, suit: card.suit});
			if (!this.cardIsPresent(maybeNewCard) && rank.rank !== card.rank.rank) {
				console.log('incrementing rank', rank);
				card.rank = rank;
				break;
			}

			if (reverse) {
				nextI = (nextI - 1) % 13;
			} else {
				nextI = (nextI + 1) % 13;
			}

			nextI++;
		}

		this.assignUnknownCardSuits([card]);
	}

	assignUnknownCardSuits(cards: Card[]): void {
		const suits = Suit.ALL;
		let i = 0;
		let didChange = false;
		cards.map(c => {
			if (c.rank.rank !== 'u' && c.suit.suit === 'u') {
				let ii = 0;
				while (ii < 4) {
					const newSuit = suits[(i + ii) % 4];
					const maybeNewCard = new Card({rank: c.rank, suit: newSuit});
					if (!this.cardIsPresent(maybeNewCard)) {
						console.log('setting card suit:', maybeNewCard);
						ii = 4;
						c.suit = newSuit;
						didChange = true;
						if (c.id === this.state.selectedCard?.id) {
							this.state.selectedCard.suit = newSuit;
						}
					}

					ii++;
				}
			}

			if (c.rank.rank !== 'u' && c.suit.suit === 'u') {
				// If still no suit, set card to unknown because there are no possible suits (because all other 4 are on
				// the board).
				c.rank.rank = 'u';
			}

			i++;
		});
	}

	assignUnknownCardRanks(cards: Card[]): void {
		const ranks = Rank.ALL;
		let i = 0;
		let didChange = false;
		// Console.log(cards)
		cards.map(c => {
			if (c.suit.suit !== 'u' && c.rank.rank === 'u') {
				let ii = 0;
				while (ii < 4) {
					const newRank = ranks[(i + ii) % 4];
					const maybeNewCard = new Card({rank: newRank, suit: c.suit});
					if (!this.cardIsPresent(maybeNewCard)) {
						console.log('setting card rank:', maybeNewCard);
						ii = 4;
						c.rank = newRank;
						didChange = true;
						if (c.id === this.state.selectedCard?.id) {
							this.state.selectedCard.rank = newRank;
						}
					}

					ii++;
				}
			}

			if (c.rank.rank !== 'u' && c.suit.suit === 'u') {
				// If still no suit, set card to unknown because there are no possible suits (because all other 4 are on
				// the board).
				c.rank.rank = 'u';
			}

			i++;
		});
	}

	async selectNextCard(reverse: boolean): Promise<void> {
		let newIndex = ((this.state.selectedCardIndex ?? 0) + 1) % (this.getAllCards().length);
		if (reverse) {
			newIndex -= 2;
			if (newIndex < 0) {
				newIndex = this.getAllCards().length + newIndex;
			}
		}

		const newCard = this.getAllCards()[newIndex];
		console.log('Selecting', newIndex, newCard);
		// Need to set focus on new item as well
		// await refOfNextFocus?.current?.focus();

		await updateCardPickerBounds(newCard, document.getElementById(newCard.id)!.parentElement! as HTMLDivElement, this);

		return this.updateStateFields('update selectedCard', {
			selectedCard: newCard,
			selectedCardIndex: newIndex,
		})
			.then(() => {
				document.getElementById(newCard.id)!.focus();
			});
	}

	hash() {
		return this.getAllCards().map(c => c.toString(false)).join('');
	}
}
