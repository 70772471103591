import {SimulationAbout} from '../simulation/simulation-about';

export function Footer() {
	return (
		<div style={{
			whiteSpace: 'pre-line',
			paddingTop: '20px',
			paddingBottom: '20px',
			paddingRight: '10%',
			paddingLeft: '10%',
		}}>
			{/* {'Footer content tbd\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n'} */}
			<div style={{width: '100%', color: 'var(--color-bad-strong)'}}>
				<i>*Note: this simulation may NEVER EVER be used in real time for table play! It can only be used for post-hand analysis once ALL players have folded the hand.</i>
			</div>
			<br></br>
			<br></br>
			<SimulationAbout></SimulationAbout>
		</div>
	);
}
