import {type Card} from '../../../poker/card';
import {type GameState} from '../../../poker/game-state';
import {Container} from 'react-bootstrap';
import React, {type CSSProperties} from 'react';
import {XButton} from '../../components/x-button';
import {CardView} from './card-view';

export type TCardsView = {
	keyPrefix?: string;
	cards: Card[];
	indexOffset?: number;
	stateManager?: GameState;
	xButtonCallback?: () => Promise<void>;
	cardFontSize?: string;
	children?: any;
	cardClassName?: string;
	backgroundColor?: string;
	borderColor?: string;
	xButtonHorizontalOffset?: number;
	style?: CSSProperties;
};

export let MIN_CONTAINER_HEIGHT = Infinity;

export function CardsView({keyPrefix, cards, indexOffset, stateManager, xButtonCallback, cardFontSize, children, cardClassName, backgroundColor = undefined, borderColor = 'var(--bw-primary)', xButtonHorizontalOffset = 0, style = undefined}: TCardsView) {
	const [isHoverActive, setIsHoverActive] = React.useState(false);

	const elRef = React.useCallback((node: HTMLDivElement) => {
		async function saveMinContainerHeight() {
			if (node !== null) {
				const currHeight = node.clientHeight;
				const newMin = Math.min(MIN_CONTAINER_HEIGHT, currHeight);
				console.log(`currMin: ${MIN_CONTAINER_HEIGHT} currHeight: ${currHeight} newMin: ${newMin}`);
				if (newMin !== MIN_CONTAINER_HEIGHT) {
					console.log('Saving curr min to', newMin);
					MIN_CONTAINER_HEIGHT = newMin;
				}
			}
		}

		saveMinContainerHeight()
			.catch(console.error);
	}, []);

	const xButtonVisible = xButtonCallback !== undefined
		&& isHoverActive
		&& stateManager?.state.selectedCard === undefined
		&& stateManager?.state.players.length !== 1;

	const ref = React.useRef<HTMLDivElement>(null);

	return <div
		ref={ref}
		onMouseEnter={async e => {
			setIsHoverActive(true);
		}}
		onMouseMove={async e => {
			// Const bounds = (e.target as any).getBoundingClientRect();
			// const x: number = e.clientX - bounds.left;
			// const y: number = e.clientY - bounds.top;
			//
			// const xArea = 0.5
			// const inXHoverArea = !!(x >= (bounds.width - (bounds.width * xArea)) && (bounds.height - (bounds.height * xArea)))
			// await setIsHoverActive(inXHoverArea)
			// return {x: x, y: y};
		}}
		onMouseLeave={async e => {
			setIsHoverActive(false);
		}}
	>
		<Container className={'card-container'} ref={elRef} style={
			{
				maxWidth: 'unset',
				borderColor,
				backgroundColor,
				...(style ?? {}),
			}
		}>
			{cards.map((card, i) => (
				<CardView
					className={cardClassName}
					card={card}
					stateManager={stateManager}
					key={'card_view_' + card.id}
					height={130}
				></CardView>
			))}
			{children}
		</Container>
		{<XButton xButtonVisible={xButtonVisible} xButtonCallback={xButtonCallback} containerHeight={ref.current?.clientHeight}></XButton>}

	</div>;
}
