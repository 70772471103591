import {type Player} from '../../../poker/player';
import {type GameState} from '../../../poker/game-state';
import React, {type CSSProperties} from 'react';
import {FloatChild} from '../../components/float-child';
import {CardsView} from '../card/cards-view';
import {Card, Cards} from '../../../poker/card';
import {OutsView} from './outs-view';
import {animateScroll} from './add-player-button-view';
import {SCROLLABLE_PLAYER_CONTAINER_ID} from '../game/game-view-desktop';

export function playerToDisplayName(player: Player) {
	return player.name + (!player.isWinner ? ': (Behind)' : player.isTie ? ': (Tied Winner)' : ' (Ahead)');
}

export function PlayerView({player, playerIndex, stateManager, style}: {player: Player; playerIndex: number; stateManager: GameState; style?: CSSProperties}) {
	const playerName = playerToDisplayName(player);
	const card_index_offset = stateManager.state.communityCards.length + (2 * playerIndex);

	const safeStyle = {
		opacity: '100%',
		...(style ?? {}),
	};

	async function xButtonCallback() {
		// safeStyle.opacity = '0%';
		const scrollContainer = document.getElementById(SCROLLABLE_PLAYER_CONTAINER_ID);
		const numPlayers = stateManager.state.players.length;
		if (scrollContainer) {
			const newScrollPosition = (scrollContainer.scrollLeft / numPlayers) * (numPlayers - 1);
			await animateScroll(newScrollPosition);
		}

		stateManager.state.players = stateManager.state.players.filter(p => p.id !== player.id);
		console.log('updating game state from remove player');
		await stateManager.updateStateFields('player x button clicked', {
			shouldRefreshGameState: true,
		});
	}

	async function onOutClicked(c: Card) {
		const playerUnknownCards = Cards.unknown(player.cards);
		let targetCard: Card | undefined;

		if (playerUnknownCards.length > 0) {
			targetCard = playerUnknownCards[0];
		} else {
			const communityCardsUnknown = Cards.unknown(stateManager.state.communityCards);
			if (communityCardsUnknown.length > 0) {
				targetCard = communityCardsUnknown[0];
			}
		}

		if (targetCard !== undefined) {
			targetCard.suit = c.suit;
			targetCard.rank = c.rank;

			await stateManager.updateStateFields('out clicked', {
				shouldRefreshGameState: true,
			});
		}
	}

	const playerHasNoCards = player.cards[0].equals(Card.UNKNOWN) && player.cards[1].equals(Card.UNKNOWN);
	const accentColor = playerHasNoCards ? undefined : player.isTie ? 'var(--color-yellow)' : player.isWinner ? 'var(--color-good-strong)' : 'var(--color-bad-strong)';
	const backgroundColor = playerHasNoCards ? undefined : player.isTie ? 'var(--color-yellow-background-opacity)' : player.isWinner ? 'var(--color-good-strong-background-opacity)' : 'var(--color-bad-strong-background-opacity)';

	return <FloatChild id={player.id} style={{...safeStyle}}>
		<h5 style={{whiteSpace: 'pre-line', color: accentColor}} >{playerName ?? '‎'}</h5>
		<h4 style={{whiteSpace: 'pre-line', color: accentColor}}>{player.handName ?? '‎'}</h4>
		<CardsView
			xButtonCallback={xButtonCallback}
			keyPrefix={`${player.id}`}
			stateManager={stateManager}
			cards={player.cards}
			indexOffset={card_index_offset}
			backgroundColor={backgroundColor}
			borderColor={accentColor}
		></CardsView>
		<OutsView
			player={player}
			stateManager={stateManager}
			onOutClicked={onOutClicked}
		></OutsView>
	</FloatChild>;
}
