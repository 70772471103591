import {type GameState} from '../../../../poker/game-state';
import React from 'react';
import {type Player} from '../../../../poker/player';
import {BasePokerBarChartView} from './base-poker-bar-chart-view';
import {GREEN_STRONG, RED_STRONG, YELLOW_STRONG} from '../../../colors';
import _ from 'lodash';
import {
	type TProbabilityResponseSample,
	type TSimulationResponsePlayer,
} from '../../../../backend/current-state/simulation-request-manager';

export const ALL_POKER_HANDS_RANKED = [
	'Royal Flush',
	'Straight Flush',
	'Four Of A Kind',
	'Full House',
	'Flush',
	'Straight',
	'Three Of A Kind',
	'Two Pair',
	'One Pair',
	'High Card',
];

export function PlayerHandWinRateChartView({stateManager, targetPlayer}: {stateManager: GameState; targetPlayer: Player}) {
	const simulation_response = stateManager.state.simulationResult;
	let player: TSimulationResponsePlayer | undefined;
	let maxMultiplier = 0;

	if (simulation_response !== undefined) {
		for (const player_id of Object.keys(simulation_response.players)) {
			if (player_id === targetPlayer.id) {
				player = simulation_response.players[player_id];
			}
		}
	}

	player?.probability.makes_hand.map(hand => {
		maxMultiplier = Math.max(maxMultiplier, hand.has_hand.p);
	});

	let data: any = player?.probability.makes_hand.map(hand_probability => {
		const multiplier = hand_probability.has_hand.p;
		return {
			hand: hand_probability.name,
			win_given_hand: (hand_probability.win_given_hand.p * multiplier),
			tie_given_hand: (hand_probability.tie_given_hand.p * multiplier),
			lose_given_hand: (hand_probability.lose_given_hand.p * multiplier),
			base_event_p: multiplier,
			samples: {
				win_given_hand: hand_probability.win_given_hand.sample,
				tie_given_hand: hand_probability.tie_given_hand.sample,
				lose_given_hand: hand_probability.lose_given_hand.sample,
			},
		};
	}) ?? [];

	if (data.length === 0) {
		data = ALL_POKER_HANDS_RANKED.map(hand => ({
			hand,
		}));
	}

	return (
		<BasePokerBarChartView
			player={targetPlayer}
			title={(targetPlayer.name + ' Hand Win Rate')}
			data={data as any[]}
			width={700}
			height={500}
			y_axis_key={'hand'}
			bars={
				[
					{name: 'Wins', dataKey: 'win_given_hand', fill: GREEN_STRONG},
					{name: 'Chops Pot', dataKey: 'tie_given_hand', fill: YELLOW_STRONG},
					{name: 'Loses', dataKey: 'lose_given_hand', fill: RED_STRONG},
				]}
			randomDataGenerator={oldData => {
				const random_p = Math.round(Math.random() * 100) / 100;
				return {
					hand: oldData.hand as string,
					win_given_hand: random_p,
					lose_given_hand: 1 - random_p,
				};
			}}
			sampleGetter={function (e, dataKey) {
				// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
				return _.get(e, 'samples.' + dataKey, undefined) as unknown as TProbabilityResponseSample;
			}}
			playerNameGetter={(e, s, p) => p?.name ?? ''}
			gameState={stateManager}
		></BasePokerBarChartView>
	);
}
