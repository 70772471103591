import {type Card, Cards} from './card';

export class Player {
	static idCounter = 1;
	static getNextId() {
		const rv = this.idCounter;
		this.idCounter++;
		return Player.idToIdStr(rv);
	}

	static idToIdStr(id: number) {
		return `player_${id}`;
	}

	static of(name: string, cards: string[]) {
		return new Player({name, cards: Cards.of(...cards)});
	}

	public name: string;
	public cards: Card[];
	public isWinner: boolean;
	public isTie: boolean;
	readonly id: string;
	private _handName?: string;

	constructor({name, cards = [], id = undefined}: {name?: string; cards?: Card[]; id?: string}) {
		this.cards = Cards.padUnknownCards(cards ?? [], 2);
		this.id = id ?? Player.getNextId();
		this.name = name ?? `Player ${this.id.split('_')[1]}`;
		this.isWinner = false;
		this.isTie = false;
	}

	get handName(): string | undefined {
		if (this._handName === '') {
			// Case where board is empty, but server returns high card since it's minimum
			return '‎';
		}

		return this._handName;
	}

	set handName(value: string | undefined) {
		this._handName = value;
	}
}
