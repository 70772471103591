import React, {type CSSProperties, type ReactElement} from 'react';
import {Button} from 'react-bootstrap';
import {BLACK_BORDER, RED_BORDER, RED_STRONG} from '../colors';

export function RedButton({style = {}, onClick, isVisible, children = ''}: {style?: CSSProperties; children?: any; onClick?: () => Promise<void>; isVisible: boolean}): ReactElement {
	const [isHoverActive, setIsHoverActive] = React.useState(false);

	return (
		<Button hidden={!isVisible} tabIndex={-1} onClick={onClick} style={{
			backgroundColor: 'var(--bw-primary)',
			color: isHoverActive ? RED_STRONG : 'var(--secondary)',
			border: isHoverActive ? RED_BORDER : BLACK_BORDER,
			borderRadius: '6px',
			...style,
		}}
		onMouseEnter={
			() => {
				setIsHoverActive(true);
			}
		}
		onMouseLeave={
			() => {
				setIsHoverActive(false);
			}
		}>{children}</Button>
	);
}
