import React, {type CSSProperties, type ReactElement} from 'react';
import {Button} from 'react-bootstrap';
import {RED_STRONG} from '../colors';

export function XButton({xButtonCallback, xButtonVisible, style = {}, containerPaddingTop = 0, containerPaddingRight = 0, containerHeight = 0, children = 'X'}: {
	xButtonCallback?: () => Promise<void>;
	xButtonVisible: boolean;
	style?: CSSProperties;
	containerPaddingTop?: number;
	containerPaddingRight?: number;
	containerHeight?: number;
	children?: any;}): ReactElement {
	return (
		<div style={{alignContent: 'right', float: 'right', height: '0', width: '0'}}>
			<Button tabIndex={-1} onClick={async () => {
				if (xButtonCallback !== undefined) {
					await xButtonCallback();
				}
			}
			} className={'x-button'} style={{

				background: !xButtonVisible ? 'transparent' : RED_STRONG,
				border: 'transparent',
				borderRadius: '50%',
				transform: `translate(calc(-100% + ${containerPaddingRight}px), calc(-75% + ${containerPaddingTop}px - ${containerHeight}px))`,
				transition: 'all 250ms ease',
				color: !xButtonVisible ? 'transparent' : 'var(--bw-secondary)',
				position: 'static',
				zIndex: 100,
				...style,
			}}>{children}</Button>
		</div>
	);
}
