import React, {type CSSProperties, type ReactNode} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import {GREEN_STRONG} from '../../colors';
import {type GameState} from '../../../poker/game-state';

function multiSelectTab({text, isSelected}: {text: string; isSelected: boolean}): ReactNode {
	return <div tabIndex={-1} style={{color: isSelected ? 'var(--bw-secondary)' : 'var(--bw-primary)'}}>{text}</div>;
}

export function MultiSelectView({stateManager, id, options, onOptionChanged, children, color = GREEN_STRONG, style = {}}: {
	id: string;
	options: Array<{
		name: string;
		content?: any;
	}>;
	onOptionChanged: (newOption: string) => Promise<void>;
	children?: any;
	color?: string;
	style: CSSProperties;
	stateManager: GameState;
}) {
	const [selectedValue, setSelectedValue] = React.useState(options[0]);

	const first_name = options[0].name;
	const last_name = options[(options.length - 1)].name;
	const nameToOption: Record<string, any> = {};

	return (
		<div style={{width: 'fit-content', ...style}} tabIndex={-1}>
			<Tabs
				tabIndex={-1}
				className={(stateManager.state.isMobile ? 'mobile ' : '') + 'nav-fill multi-select'}
				id={id}
				onSelect={async key => {
					if (key !== null) {
						await onOptionChanged(key);
						setSelectedValue(nameToOption[key]);
					}
				}}
			>
				{options.map(option => {
					nameToOption[option.name] = option;
					const tabPosition = option.name === first_name ? 'tab-first' : option.name === last_name ? 'tab-last' : '';
					const tabClassName = 'multi-select-tab ' + (stateManager.state.isMobile ? 'mobile ' : '') + tabPosition;
					return <Tab
						title={multiSelectTab({text: option.name, isSelected: option.name === selectedValue.name})}
						tabIndex={-1}
						key={option.name}
						eventKey={option.name}
						tabClassName={tabClassName}
						style={{
							// Border: '2px solid black'
						}}
					>{option.content}</Tab>;
				})}
			</Tabs>
			<div>
				{children}
			</div>
		</div>
	);
}
