// Game State Types
import {Cards} from '../../poker/card';
import {type GameState} from '../../poker/game-state';
import {Buffer} from 'buffer';

export type TCardIdOnly = {
	id: string;
};

export type TCardValueOnly = string;

export type TCurrentStatePlayerResponse = {
	id: string;
	winner: boolean;
	outs: Record<string, TCardValueOnly[]>;
	hand: {
		cards: {
			cards_in_hand: TCardIdOnly[];
			cards_not_in_hand: TCardIdOnly[];
		};
		name: string;
	};
};

export type TCurrentStateResponse = {
	players: TCurrentStatePlayerResponse[];
};

export type TCard = {
	id: string;
	value: string;
};

// Simulation Types
export type TCurrentStateRequest = {
	request_id: string;
	players: Array<{
		id: string;
		cards: TCard[];
	}>;
	community_cards: TCard[];
};

export type TCurrentStateMappedOut = {
	name: string;
	cards: TCardValueOnly[];
};

export type TCurrentStateMappedPlayer = (Omit<TCurrentStatePlayerResponse, 'outs'> & {
	outs: TCurrentStateMappedOut[];
});

export type TCurrentStateMappedResponse = {
	players: TCurrentStateMappedPlayer[];
};

export type TSimulationRequest = TCurrentStateRequest & {
	num_players: number;
	num_simulations: number;
	include_samples: boolean;
	include_moe: boolean;
};

export type TProbabilityResponseSample = {
	community_cards: TCardValueOnly[];
	players: Record<string, {
		cards: TCardValueOnly[];
	}>;
};

export type TProbabilityResponse = {
	p: number;
	['moe']: number;
	sample?: TProbabilityResponseSample;
};

export type TSimulationResponsePlayer = {
	id: string;
	probability: {
		win: TProbabilityResponse;
		tie: TProbabilityResponse;
		lose: TProbabilityResponse;
		makes_hand: Array<{
			name: string;
			has_hand: TProbabilityResponse;
			win_given_hand: TProbabilityResponse;
			tie_given_hand: TProbabilityResponse;
			lose_given_hand: TProbabilityResponse;
		}>;
	};
};

export type TSimulationWebsocketErrorData = {
	errors: any[];
};

export type TSimulationWebsocketStatusPhase = 'simulating' | 'analyzing' | 'completed' | 'error';

export type TSimulationWebsocketAckData = {
	request_id: string;
};

export type TSimulationWebsocketStatusData = {
	percent_complete: number;
	phase: TSimulationWebsocketStatusPhase;
};

export type TSimulationResponse = {
	players: Record<string, TSimulationResponsePlayer>;
};

export type TSimulationWebsocketEventType = 'error' | 'status' | 'response';

export type TSimulationWebsocketEvent = {
	type: TSimulationWebsocketEventType;
	request_id: string;
	data: TSimulationWebsocketErrorData | TSimulationResponse | TSimulationWebsocketStatusData
	| TCurrentStateResponse | TSimulationWebsocketAckData;
};

export type TSimulationResponseProcessed = {
	players: Record<string, TSimulationResponsePlayer>;
	moe?: number;
};

export type TInitializeSimulationWebsocket = {
	stateManager: GameState;
};

export type TSimulationSocketRequest = {
	stateManager: GameState;
};

export const ENDPOINT = 'https://api.sandbox.montepython.app/v1/simulate';
// Export let simulationInProgress: boolean

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SimulationRequestManager {
	public static isSimulationInProgress = false;
	public static simulationStatus: string | undefined;

	static generateId(): string {
		return SimulationRequestManager.encode(Date.now().toString());
	}

	static async sendSimulation({stateManager}: TSimulationSocketRequest): Promise<TSimulationResponseProcessed | undefined> {
		SimulationRequestManager.isSimulationInProgress = true;
		SimulationRequestManager.simulationStatus = 'In Progress';
		const body = SimulationRequestManager.buildSimulationRequest(stateManager);
		SimulationRequestManager.mostRecentSimulationId = body.request_id;

		let i = 1;

		const timer = setInterval(async () => {
			i++;
			i %= 4;
			SimulationRequestManager.simulationStatus = 'In Progress' + new Array(i).fill('.').join('');
			await stateManager.updateStateFields('simulation status dots', {}, false);
		}, 200);

		// Return await SimulationWebsocket.sendMessage(req, body)
		const res: TSimulationResponse = await fetch(ENDPOINT, {
			method: 'POST',
			headers: {'content-type': 'application/json'},
			body: JSON.stringify(body),
		})
			.then(async r => await r.json() as TSimulationResponse);
		// Const res = await axios.post(ENDPOINT, body)

		clearInterval(timer);

		if (body.request_id === this.mostRecentSimulationId) {
			this.isSimulationInProgress = false;
			// StateManager.state.simulationInfoText = 'Simulation Complete'
			// await stateManager.updateStateFields('simulation complete text update', {
			//     simulationInfoText: 'Simulation Complete'
			// })
			SimulationRequestManager.simulationStatus = undefined;
			// Const newValue = 'Simulation Complete!'
			// stateManager.state.simulationInfoText = newValue
			// await stateManager.updateStateFields('info text callback', {
			//     simulationInfoText: newValue
			// })
			return this.mapSimulationResponse(res);
		}

		return undefined;
	}

	private static readonly resultMap: Record<string, TSimulationResponseProcessed> = {};
	private static mostRecentSimulationId?: string;

	private static encode(s: string): string {
		return Buffer.from(s, 'binary').toString('base64');
	}

	private static buildSimulationRequest(stateManager: GameState): TSimulationRequest {
		return {
			request_id: SimulationRequestManager.generateId(),
			num_players: stateManager.state.players.length,
			num_simulations: stateManager.state.numSimulations,
			include_samples: true,
			include_moe: true,
			players: stateManager.state.players.map(p => ({
				id: p.id,
				cards: Cards.nonUnknown(p.cards).map(c => ({
					id: c.id,
					value: c.rank.rank + c.suit.suit,
				})),
			})),
			community_cards: Cards.nonUnknown(stateManager.state.communityCards).map(c => ({
				id: c.id,
				value: c.rank.rank + c.suit.suit,
			})),
		};
	}

	private static mapSimulationResponse(simulationResponse: TSimulationResponse): TSimulationResponseProcessed {
		let maxMoe = 0;
		Object.keys(simulationResponse.players).map(playerName => {
			const player = simulationResponse.players[playerName]!;
			maxMoe = Math.max(
				maxMoe,
				player.probability.lose.moe,
				player.probability.tie.moe,
				player.probability.win.moe,
			);
			player.probability.makes_hand.map(hand_probability => {
				maxMoe = Math.max(
					maxMoe,
					hand_probability.win_given_hand.moe,
					hand_probability.tie_given_hand.moe,
					hand_probability.lose_given_hand.moe,
					hand_probability.has_hand.moe,
				);
			});
		});
		return {
			players: simulationResponse.players,
			moe: maxMoe,
		};
	}
}
