export const GREEN_STRONG = 'var(--color-good-strong)';
export const GREEN_WEAK = 'var(--color-good-weak)';
export const GREEN_BORDER = '2px solid ' + GREEN_STRONG;

export const YELLOW_STRONG = 'var(--color-yellow)';
export const YELLOW_WEAK = 'var(--bw-middle)';
export const YELLOW_BORDER = '2px solid ' + YELLOW_STRONG;

export const RED_STRONG = 'var(--color-bad-strong)';
export const RED_WEAK = 'var(--color-bad-weak)';
export const RED_BORDER = '2px solid ' + RED_STRONG;

export const BLUE_STRONG = 'var(--color-blue)';
export const BLUE_WEAK = 'var(--bw-middle)';
export const BLUE_BORDER = '2px solid ' + GREEN_STRONG;

export const WHITE_BORDER = '2px solid var(--bw-primary)';
export const BLACK_BORDER = '2px solid var(--bw-secondary)';
export const GRAY_BORDER = '2px solid var(--bw-middle)';

export function strongToWeakColor(strongColor: string): string {
	if (strongColor === GREEN_STRONG) {
		return 'var(--bw-primary)';
	}

	if (strongColor === RED_STRONG) {
		return 'var(--bw-middle)';
	}

	if (strongColor === YELLOW_STRONG) {
		return 'var(--bw-secondary)';
	}

	throw Error('Error mapping strongToWeakColor with argument: ' + strongColor);
}
