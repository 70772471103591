import {Player} from '../../../poker/player';
import {type GameState} from '../../../poker/game-state';
import React, {type CSSProperties} from 'react';
import {FloatChild} from '../../components/float-child';
import {Button} from 'react-bootstrap';
import {SCROLLABLE_PLAYER_CONTAINER_ID} from '../game/game-view-desktop';

export async function animateScroll(to: number): Promise<void> {
	const scrollContainer = document.getElementById(SCROLLABLE_PLAYER_CONTAINER_ID);

	if (scrollContainer !== null) {
		scrollContainer.scrollTo({
			left: to,
			behavior: 'smooth',
		});
		// eslint-disable-next-line no-promise-executor-return
		return new Promise(resolve => setTimeout(resolve, 100));
	}

	return Promise.resolve();

	// if (scrollContainer === null) {
	// 	return;
	// }
	//
	// const from = scrollContainer.scrollLeft;
	//
	// const duration = 200;
	// const seconds = duration / 1000;
	// const frames = 240 * seconds;
	// const durationPerFrame = duration / frames;
	// const scrollPerFrame = (to - from) / frames;
	// const isReverse = from > to;
	//
	// let currProgress = from;
	//
	// return new Promise<void>(resolve => {
	// 	function incrementProgress(from: number, to: number) {
	// 		if (currProgress >= to && !isReverse) {
	// 			resolve();
	// 			return;
	// 		}
	//
	// 		if (isReverse && currProgress <= to) {
	// 			resolve();
	// 			return;
	// 		}
	//
	// 		console.log('animating progress', currProgress);
	//
	// 		scrollContainer!.scrollLeft = currProgress;
	// 		currProgress += scrollPerFrame;
	//
	// 		return setTimeout(async () => {
	// 			incrementProgress(currProgress, to);
	// 		}, durationPerFrame);
	// 	}
	//
	// 	incrementProgress(currProgress, to);
	// });
}

export function AddPlayerButtonView({stateManager, style}: {stateManager: GameState; style?: CSSProperties}) {
	async function onClick(e: any) {
		const newPlayerI = stateManager.state.players.length + 1;
		const newPlayer = new Player({
			id: Player.idToIdStr(newPlayerI),
		});
		stateManager.state.players.push(newPlayer);
		console.log('updating game state from add player button');
		await stateManager.updateStateFields('add player clicked', {
			shouldRefreshGameState: true,
		});
		const scrollContainer = document.getElementById(SCROLLABLE_PLAYER_CONTAINER_ID);
		if (scrollContainer) {
			await animateScroll(scrollContainer.scrollWidth - scrollContainer.clientWidth);
		}
	}

	style = {
		// Height: `${MIN_CONTAINER_HEIGHT}px`,
		borderColor: 'var(--bw-middle)',
		borderWidth: '2px',
		width: 'unset',
		position: 'relative',
		// top: '50%',
		// transform: 'translateY(-50%)',
		paddingTop: 0,
		marginTop: 0,
		transition: 'all 0.5s',
		maxHeight: '200px',
		...style,
	};

	return <FloatChild style={{display: 'flex', flexDirection: 'column', paddingRight: 0, paddingTop: 0, paddingBottom: 0}}>
		<h5 style={{whiteSpace: 'pre-line'}}>Add</h5>
		<h4 style={{whiteSpace: 'pre-line'}}>‎</h4>
		<div style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
			<Button className='add-button' onClick={onClick} style={style}>+</Button>
		</div>
	</FloatChild>;
}
