import {type GameState} from '../../../poker/game-state';
import React from 'react';
import {
	SimulationResultsView,
} from '../simulation/simulation-results-view';
import {InfoOverlayView} from '../overlays/info-overlay-view';
import {SimulationInfoView} from '../simulation/simulation-info-view';
import {HorizontalLine} from '../../components/horizontal-line';
import {Footer} from '../menus/footer';
import {InputView} from './input-view';
import {CardPickerBox} from '../card/card-picker-box';

export const COMMUNITY_CARDS_CONTAINER_ID = 'community_cards_container';
export const SCROLLABLE_PLAYER_CONTAINER_ID = 'scrollable_player_container';

export function GameViewDesktop({stateManager}: {stateManager: GameState}) {
	async function onInfoToggle() {
		console.log('info clicked');
		await stateManager.updateStateFields('info toggled', {
			isInfoOverlayVisible: !stateManager.state.isInfoOverlayVisible,
		});
	}

	return (
		<div style={{height: '100%', width: '100%', display: 'inline-block', color: 'var(--bw-primary)', margin: '0 auto'}}>
			<link
				rel='stylesheet'
				href='https://fonts.googleapis.com/icon?family=Material+Icons'
			/>
			<CardPickerBox
				stateManager={stateManager}
				style={{
					marginLeft: `${stateManager.state.hoveringCardPickerMarginLeft}px`,
					marginTop: `${stateManager.state.hoveringCardPickerMarginTop}px`,
				}}
				hidden={stateManager.state.hoveringCardPickerHidden}
			></CardPickerBox>
			{stateManager.state.isInfoOverlayVisible && <InfoOverlayView onInfoToggle={onInfoToggle} stateManager={stateManager}></InfoOverlayView>}
			<div style={{display: 'inline-block'}}>
				<br/>
				<h3 style={{padding: '10px'}}>Poker Simulator</h3>
				<h5 style={{padding: '10px'}}>By Ellek Linton</h5>
				<br/>
				<SimulationInfoView stateManager={stateManager}></SimulationInfoView>
			</div>
			<HorizontalLine></HorizontalLine>
			<div className={'command-center-view'}>
				<InputView stateManager={stateManager} onInfoToggle={onInfoToggle}></InputView>
				 {/* <VerticalLine style={{marginTop: '-20px', marginBottom: '-20px'}}></VerticalLine> */}
				<SimulationResultsView stateManager={stateManager}></SimulationResultsView>
			</div>
			<HorizontalLine></HorizontalLine>
			<Footer></Footer>
		</div>
	);
}
