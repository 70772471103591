import React from 'react';

export function HorizontalLine() {
	return <hr
		style={{
			color: 'var(--bw-primary)',
			backgroundColor: 'var(--bw-primary)',
			height: 1,
			padding: 0,
			margin: 0,
			opacity: '30%',
		}}
	/>;
}
