import {type GameState} from '../../../poker/game-state';
import React from 'react';
import {MultiSelectView} from './multi-select-view';

export const SIMULATION_MODE_TEST = 'Test (50 simulations)';
export const SIMULATION_MODE_FAST = 'Fast (500 simulations)';
export const SIMULATION_MODE_NORMAL = 'Normal (2000 simulations)';
export const SIMULATION_MODE_EXTREME = 'Extreme (5000 simulations)';
export const SIMULATION_MODE_BATSHIT = 'Batshit (20000 simulations)';

export const SIMULATION_MODES = [
	// SIMULATION_MODE_TEST,
	SIMULATION_MODE_FAST,
	SIMULATION_MODE_NORMAL,
	SIMULATION_MODE_EXTREME,
	SIMULATION_MODE_BATSHIT,
];

async function onSimulationModeChange(stateManager: GameState, newMode: string) {
	console.log('updating simulation mode:', newMode);
	switch (newMode) {
		case SIMULATION_MODE_TEST:
			console.log('updating game state from num simulations change');
			await stateManager.updateStateFields('simulation mode changed', {
				numSimulations: 50,
				shouldRefreshGameState: true,
				simulationProgress: 0,
			});
			break;
		case SIMULATION_MODE_FAST:
			console.log('updating game state from num simulations change');
			await stateManager.updateStateFields('simulation mode changed', {
				numSimulations: 500,
				shouldRefreshGameState: true,
				simulationProgress: 0,
			});
			break;
		case SIMULATION_MODE_NORMAL:
			console.log('updating game state from num simulations change');
			await stateManager.updateStateFields('simulation mode changed', {
				numSimulations: 2000,
				shouldRefreshGameState: true,
				simulationProgress: 0,
			});
			break;
		case SIMULATION_MODE_EXTREME:
			console.log('updating game state from num simulations change');
			await stateManager.updateStateFields('simulation mode changed', {
				numSimulations: 5000,
				shouldRefreshGameState: true,
				simulationProgress: 0,
			});
			break;
		case SIMULATION_MODE_BATSHIT:
			console.log('updating game state from num simulations change');
			await stateManager.updateStateFields('simulation mode changed', {
				numSimulations: 20000,
				shouldRefreshGameState: true,
				simulationProgress: 0,
			});
			break;
		default:
			throw new Error('Error: Unrecognized onSimulationModeChange newMode: \'' + newMode + '\'');
	}
}

export function NumSimulationsPicker({id, options, stateManager, children}: {
	id: string;
	options: string[];
	stateManager: GameState;
	children?: any;
}) {
	return <MultiSelectView stateManager={stateManager} style={{paddingTop: '0', paddingBottom: '0%'}} id={id} options={options.map(o => ({name: o}))} onOptionChanged={async e => {
		await onSimulationModeChange(stateManager, e);
	}}>
		{children}
	</MultiSelectView>;
}
