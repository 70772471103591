import {Container} from 'react-bootstrap';
import React, {type CSSProperties} from 'react';

export function ContainerNoBorderView({children, style, className = 'no-border', onMouseEnter, onMouseLeave, id}:
{
	children: any;
	style?: CSSProperties;
	className?: string;
	onClick?: () => void;
	onMouseEnter?: React.MouseEventHandler<HTMLElement>;
	onMouseLeave?: React.MouseEventHandler<HTMLElement>;
	id?: string;
}) {
	return (
		<Container id={id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={className} style={{border: '0px', alignItems: 'center', ...style}}>
			{children}
		</Container>
	);
}
