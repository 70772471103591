import {type Card} from '../../../poker/card';
import {type GameState} from '../../../poker/game-state';
import {Button} from 'react-bootstrap';
import React, {type CSSProperties, type ReactElement} from 'react';

import {ContainerNoBorderView} from '../containers/container-no-border-view';
import {MINI_CARD_ASPECT_RATIO} from './mini-cards-view';
import {
	CARD_PICKER_HEIGHT,
	eventWasInsideBound,
} from './card-picker-box';

export type TCardView = {
	card: Card;
	className?: string;
	stateManager?: GameState;
	style?: CSSProperties;
	onClick?: (c: Card) => Promise<void>;
	onHoverStart?: (c: Card) => Promise<void>;
	onHoverEnd?: (c: Card) => Promise<void>;
	renderBorder?: boolean;
	id?: string;
	height?: number;
	width?: number;
	needsRef?: boolean;
};

// https://code.google.com/archive/p/vector-playing-cards/downloads
function imgOfCard(card: Card) {
	return `./images/cards/${card.toString(false)}.svg`;
	// Return card_images[card.toString(false)]
}

const buttonImgCache: Record<number, Record<number, Record<string, ReactElement>>> = {};

function getOffset(el: HTMLElement) {
	const rect = el.getBoundingClientRect();
	return {
		left: rect.left + window.scrollX,
		top: rect.top + window.scrollY,
		width: rect.width,
	};
}

export function ElRefDiv(elementStateChanged: (node: HTMLDivElement) => Promise<void>) {
	return React.useCallback((node: HTMLDivElement) => {
		async function saveMinContainerHeight() {
			if (node !== null) {
				await elementStateChanged(node);
			}
		}

		saveMinContainerHeight()
			.catch(console.error);
	}, []);
}

export async function updateCardPickerBounds(card: Card, div: HTMLDivElement, stateManager?: GameState, hidden = false) {
	const bounds = getOffset(div);
	// Await safeOnClick(card);
	// const rect = getOffset()
	console.log('selected card bounds for div', div);

	const newMarginLeft = bounds.left + bounds.width - 2;
	const newMarginTop = bounds.top - ((CARD_PICKER_HEIGHT - div.clientHeight) / 2);

	if (stateManager?.state.userClosedCardPicker === true
        || stateManager?.state.hoveringCardPickerHidden !== hidden
        || stateManager?.state.hoveringCardPickerMarginLeft !== newMarginLeft
        || stateManager?.state.hoveringCardPickerMarginTop !== newMarginTop
	) {
		await stateManager?.updateStateFields('updating hoveringCardPicker margins', {
			selectedCard: card,
			userClosedCardPicker: false,
			hoveringCardPickerHidden: hidden,
			hoveringCardPickerMarginLeft: newMarginLeft,
			hoveringCardPickerMarginTop: newMarginTop,
		});
	}
}

export function CardView({card, className, stateManager, style = {}, id, onClick, onHoverStart, onHoverEnd, renderBorder = true, height = 150, width = undefined, needsRef = true}: TCardView) {
	// Const [hoverActive, setHoverActive] = React.useState(false);
	const isSelected = stateManager?.state.selectedCard?.id === card.id;
	const ref = React.useRef<HTMLDivElement>(null);
	const [isHoverActive, setIsHoverActive] = React.useState<boolean>(false);

	async function defaultOnClick() {
		// Await setSelectedCard(card, stateManager)

		if (stateManager?.state.hoveringCardPickerHidden) {
			// Await stateManager.updateStateFields('Setting card picker visible after click', {
			//     hoveringCardPickerHidden: false
			// })
		}
	}

	async function defaultOnFocus(e: any) {
		if (stateManager?.state.selectedCard?.id !== card.id) {
			// Await setSelectedCard(card, stateManager)
		}
	}

	const buttonStyle: CSSProperties = {
		backgroundColor: 'transparent',
		borderRadius: renderBorder ? '12px' : '0',
		transform: isSelected ? 'scale(1.2)' : '',
		transition: 'all 250ms ease',
		margin: '4px',
		padding: '6px',
		top: 0,
		...style,
		height: 'fit-content',
		width: 'fit-content',
	};

	const safeOnClick = onClick ?? defaultOnClick;
	let attributes = '';
	if (renderBorder) {
		if (card.inWinningHand) {
			attributes = 'winner';
		} else if (card.inWinningHandSecondary) {
			attributes = 'winner-secondary';
		}

		if (isSelected) {
			attributes = 'selected';
		}
	}

	const finalClassName = `shadow-none card-button ${attributes}${(className === undefined ? '' : ' ' + className)}`;

	const imageSource = imgOfCard(card);

	if (width === undefined) {
		width = height / MINI_CARD_ASPECT_RATIO;
	}

	if (buttonImgCache[height] === undefined) {
		buttonImgCache[height] = {
			[width]: {
				[card.toString(false)]: <img src={imageSource as unknown as string} style={{height, width}} alt={card.toString()}/>,
			},
		};
	} else if (buttonImgCache[height][width] === undefined) {
		buttonImgCache[height][width] = {
			[card.toString(false)]: <img src={imageSource as unknown as string} style={{height, width}} alt={card.toString()}/>,
		};
	} else if (buttonImgCache[height][width][card.toString(false)] === undefined) {
		buttonImgCache[height][width][card.toString(false)] = <img src={imageSource as unknown as string} style={{height, width}} alt={card.toString()}/>;
	}

	return <ContainerNoBorderView
		onMouseEnter={async e => {
			setIsHoverActive(true);
			if (document.getElementById(card.id)?.parentElement !== undefined) {
				await updateCardPickerBounds(card, document.getElementById(card.id)!.parentElement! as HTMLDivElement, stateManager);
			}

			if (onHoverStart !== undefined) {
				await onHoverStart(card);
			}
		}}
		onMouseLeave={async e => {
			setIsHoverActive(false);

			if (stateManager !== undefined) {
				const cardBounds = document.getElementById(card.id)!.getBoundingClientRect();
				const eventIsOverCurrentCard = eventWasInsideBound(e, cardBounds, {
					right: 20,
				});
				if (!eventIsOverCurrentCard) {
					await stateManager.updateStateFields('card view hover ended, unselecting card', {
						selectedCard: undefined,
						hoveringCardPickerHidden: true,
					});
				} else {
					console.log('not stopping card view hover because event is over current card with bounds');
				}
			}

			// Await setHoverActive(false)
			if (onHoverEnd !== undefined) {
				await onHoverEnd(card);
			}
		}}
		style={{
			alignItems: 'center',
			padding: 0,
			gap: '2%',
		}}
	>
		<div ref={needsRef ? ref : undefined}>
			<Button
				// Hidden={shouldShowPicker}
				id={id ?? card.id}
				className={ finalClassName }
				style={{
					...buttonStyle,
				}}
				onClick={async e => {
					console.log((e.target as HTMLDivElement).parentElement!.parentElement!.parentElement);
					await safeOnClick(card);
					// Await updateSelectedCardBounds((e.target as HTMLDivElement).parentElement!.parentElement!.parentElement as HTMLDivElement);
				}}
				onFocus={async e => {
					await defaultOnFocus(e);
				}}
			>
				{buttonImgCache[height][width][card.toString(false)]}
			</Button>
		</div>
	</ContainerNoBorderView>;
}
