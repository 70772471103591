import React from 'react';
import {Button, Container} from 'react-bootstrap';

export function SimulationAbout() {
	const simulationText = `The results and statistics above were generated by running a monte-carlo style poker simulation. Basically, we took the above poker game, and simulated the possible outcomes thousands of times, and then computed statistics from those results. Because of this, there is a "margin of error", which will be discussed further below.
    
            Most poker probability calculators do not use simulations to calculate statistics, but rather, use something called a Lookup Table, which is essentially a huge table full of pre-calculated poker statistics for every possible hand and table state. These are extremely fast to use when calculating poker probabilities, however, they have one major drawback: they provide NO access to deeper statistics, such as how frequently you will hit a flush, or how often your opponent(s) will have better cards than you. For example, if you lost a huge pot with pocket aces (AA), and in retrospect, you know what your opponents card were (pocket kings, KK), what would be the probability of your opponent beating you with those cards? It is impossible to calculate these probabilities with a Lookup Table.
            
            You may wonder, "Well, can't we just use math to calculate these probabilities directly? Why do we even need simulations?". While technically this is possible, in practice, it is nearly impossible to execute on a practical level. Let's look at one example. There are 52 cards in a deck, and you have pocket Aces (AA). This leaves 50 remaining cards in the deck that could be either on the table, or in your opponent's hand. Let's say there are only 2 players at the table. So then, there are 7 total unknown cards in this table state: the 5 table cards + your only opponent's 2 cards. If using direct math to calculate a probability event with this table state, you would need to iterate over every possible outcome. You have 7 unknown variables (cards), and you have 50 possible values that each of those 7 cards could be. How many possible outcomes are there? 
            
            In math, this is called "50 choose 7", which is equal to 99,884,400 possible outcomes. And for each of these possible outcomes, you would need to perform computationally expensive calculations in order to compute the desired probability. That would probably take a modern computer several hours if not days to compute just a single probability. If this website used direct math, that means it would take several days to several hours for the simulation results to appear below each time the poker state is changed. It's just not feasible!
            
            So, what if we want to calculate deeper poker probabilities without having to wait potentially several hours for results? Unfortunately, This was not really possible before for the average joe. Resource intensive software capable of this was only accessible to rich corporations and individuals, and even that still took probably hours to compute. However, after hundreds of hours of coding, I have created a completely new poker probability engine 100% from scratch that is capable of calculating ANY poker probability in a split second, in real time, for any game state, any number of players, and any number of known or unknown cards. I have decided to call this engine "Pied Poker", which is derived from the bar that introduced me to poker, Piper Down.
            
            Pied Poker is the probability engine behind this website, and you can play around with by changing the above card values (the simulation will automatically be triggered when a card value or player changes). For example, add 6 players to the table above, and give one of the players pocket aces (AA). How strong do you think the pocket aces really are? You can find out!`;

	const [textExpanded, setTextExpanded] = React.useState(false);

	return (
		<Container style={{
			paddingTop: '20px',
			paddingBottom: '20px',
			border: '0',
		}}>
			<div>
				<div
					style={{
						whiteSpace: 'pre-line',
						textAlign: 'left',
						paddingRight: '5%',
						paddingLeft: '5%',
						// Width: infoWidth !== undefined ? infoWidth + 'px' : '0'
					}}
					className={'fading-gradient' + (textExpanded ? 'expanded' : '')}
				>{simulationText}
				</div>
				<Button
					className={'poker-button expand-button'}
					onClick={async () => {
						setTextExpanded(!textExpanded);
					}}>{textExpanded ? 'Collapse Text' : 'Expand Text'}</Button>
			</div>
		</Container>
	);
}
