import {NumSimulationsPicker, SIMULATION_MODES} from '../menus/num-simulations-picker';
import React from 'react';
import {type GameState} from '../../../poker/game-state';
import {SimulationRequestManager} from '../../../backend/current-state/simulation-request-manager';

export function SimulationInfoView({stateManager}: {stateManager: GameState}) {
	return (
		<div>
			<NumSimulationsPicker id={'num_simulations_picker'}
				options={SIMULATION_MODES}
				stateManager={stateManager}
			>
			</NumSimulationsPicker>
			<div style={{whiteSpace: 'pre-line', padding: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
				<div>Simulation Status: <strong>{SimulationRequestManager.simulationStatus ?? 'Simulation Complete!'}</strong></div>
				<div>Number of Simulations: <strong>{stateManager.state.numSimulations.toLocaleString()}</strong></div>
			</div>
		</div>
	);
}
